.btn-loader {
    border: 5px solid #3D3D3D;
    border-top: 5px solid  #69A227;;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1.5s linear infinite;
}

.lg-loader {
    border: 15px solid #3D3D3D;
    border-top: 15px solid  #69A227;;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1.5s linear infinite;
}

.lg-loader-box {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
   
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}