/* ---------------------------------- Responsive Media Styles ---------------------------------*/



@media (min-width: 1200px) {
    
	// .container { max-width: 1347px; }
}




 

@media only screen and (min-width: 769px) {
	 
	//  .tophead .tophead-right .dropdown-menu { display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:0; top:180%;  }
	// .tophead .tophead-right  .dropdown-toggle:hover .dropdown-menu { transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }
	 
	//  .navbar .nav-item .dropdown-menu {  display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:0; top: 180%  }  
	// .navbar .nav-item:hover .dropdown-menu { transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }  

}





/* Medium : 647px */

@media only screen and (max-width: 768px) {

}

@media only screen and (min-width: 576px) and (max-width:1024px) {

	// tab-screen

	.tophead ul li {font-size: 12px; padding-right: 19px;}

	
}

/* Small : 320px */

@media screen and (max-width: 575px) {

// .hero-section { background: url(../images/sunset-sky.png) right 65px; background-repeat: no-repeat; background-size: 60%; }

.hero-right { margin-top: 0px;  }
.hero-rightimg { height: auto; }

.bannerhead-textcontent h1 { font-size: 25px; }
.banner-content h6 { font-size: 13px;  margin-bottom: 15px; }
.serch-partbox { padding: 15px 10px; }
.search-input { padding: 25px 8px; font-size: 12px; }
.search-btn { font-size: 12px;  padding: 6px 18px; }
.btn-position { right: 8px; top: 41px; }
.banner-content h4 { font-size: 20px; }
.banner-content p { font-size: 13px; }
.sea-section { background: linear-gradient(90deg, #69a227 100%, #f4f4f4 30%); }
.land-box { padding: 10px 15px; }
.land-box h4 { font-size: 20px; }
.land-box p { font-size: 13px; padding-top: 5px; line-height: 20px; }
.sea-btn { font-size: 13px; padding: 5px 20px; }
.sectop-headcontent h3 { font-size: 22px; }
.customer-text h6 { font-size: 22px; padding-top: 10px; }
.customer-text h5 { font-size: 13px; padding-top: 6px; line-height: 20px; }
.satisfied-customers { padding: 15px 10px; } 
.satisfied-customers h6 { font-size: 30px; }
.satisfied-customers p { font-size: 12px; padding-top: 5px; }
.customers-section { background-size: 50%; }
.satisfied-content h4 {  font-size: 13px;  padding-bottom: 13px; }
.deals-promotionscontent h2 { font-size: 18px; padding-top: 10px; }
.deals-promotionscontent h6 {  font-size: 16px; margin-bottom: 20px; padding-top: 5px;}
.arrow-box { padding: 5px 10px; }
.ship-imgbox img {  height: 50px; }
.noflexwrap {  flex-wrap: inherit;  overflow-x: scroll; }
.range-productbox h6 { font-size: 18px; }
.range-productimgbox { height: 175px; }
.range-productcontentbox { padding: 15px 15px; }
.range-productcontentbox h2 { font-size: 16px; }
.range-productcontentbox p { font-size: 13px; padding-top: 8px; }
.view-detailsbtn { padding: 4px 12px; font-size: 13px; }
.range-productcontentbox h3 { font-size: 14px; }
.product-box { width: 122px; top: 9px; padding: 2px 8px; font-size: 12px; }
.why-sec { padding-top: 4px; padding-left: 10px; }
.why-sec h2 { font-size: 22px; }
.online-text { font-size: 13px; }
.plain-section { height: auto; background-position: center; background-size: cover; }
.black-whyright { height: 100px; }
.parts-textcontent { padding: 12px 10px; }
.parts-imgbox { height: 150px; }
.parts-textcontent h3 { font-size: 15px; }
.parts-textcontent h6 { font-size: 13px; padding-top: 2px; line-height: 20px; }
.verified-seller {  padding: 12px 10px; }
.verified-seller h3 { font-size: 15px; }
.verified-seller h6 { font-size: 13px; padding-top: 2px; line-height: 20px; }
.top-bannerimg h2 { font-size: 25px; top: 84px; }
.standard-plan { padding: 50px 12px 10px 12px; }
.standard-plan p { font-size: 13px; }
.standard-plan h6 { font-size: 13px; }
.standard-btn { font-size: 15px; padding: 8px 18px; margin-bottom: -30px; }
.premiumplan-btn { font-size: 15px; padding: 8px 18px; margin-bottom: -30px; }
.sectop-headcontent p { font-size: 14px;  margin-bottom: 5px; }
.blog-imgbox { height: 185px; }
.card-box p { font-size: 14px; }
.card-box h3 { font-size: 16px; padding-top: 7px; }
.testimonial-box h6 { font-size: 16px; margin-bottom: 8px; }
.testimonial-sec { height: 100%; }
.testimonial-userpic { width: 85px; height: 85px; }
.testimonial-contentbox h2 { font-size: 14px; margin-bottom: 14px; padding-top: 5px; }
.testimonial-contentbox p { font-size: 12px; padding-top: 10px; }
.testimonial-box { padding: 15px 15px; margin-top: 15px; }
.review-btn { font-size: 12px; padding: 5px 15px; }
.tree-imgbox { bottom: 15px; right: -76px; }
.app-qrsec::after { display: none; }
.app-qrsec::before { display: none; } 
.app-qrsec h4 { font-size: 17px; }

.foot-righttext h2 { font-size: 16px; margin-bottom: 12px; }
.foot-righttext ul li { font-size: 13px; padding-bottom: 8px; }
.foot-logo { height: 35px; }
.foot-contnet img { height: 20px; }
.copy-righttext { font-size: 12px; }
.login-btn { font-size: 12px; padding: 3px 10px; }
.logo-mobile { height: 28px; }
.search-label { font-size: 14px; }


//===========
.explore-box {
    position: absolute;
    top: 1px;
    left: 65%;
}
.explore-box .before-explore { width: 40px; }
.explore-box:hover .after-explore {  width: 50px; }


// ============================================
.push-notification-pop .modal-content{ width: auto;}
.push-notification-pop {left: 0px; top: 0px;}
.push-headtext h1 { font-size: 18px;}
.push-headtext h5 { font-size: 13px}
.push-tabs-pop .nav-tabs .nav-item { margin-right: 10px;}
.push-tabs-pop .nav-tabs .nav-item .nav-link { font-size: 12px;}
.push-notification-pop .tab-content { padding-left: 15px;}

}

@media (min-width: 320px) and (max-width: 440px) {
	 
}

 