@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
html { font-family: 'Lato', sans-serif; -webkit-font-smoothing: antialiased; }
#seller-panel { font-family: 'Lato', sans-serif; -webkit-font-smoothing: antialiased; background: #69A227; }

#seller-panel img { max-width: 100%;}
#seller-panel a { text-decoration: none !important; }
#seller-panel .fw-medium { font-weight: 500}
#seller-panel .fw-semi { font-weight: 600}

#seller-panel header { position: relative; background: transparent !important; border: 0px !important;}
// #seller-panel .tophead {  padding-bottom: 0.5rem; padding-top: 0.5rem; }
#seller-panel .tophead { padding: 5px; border: 1px solid rgba(105, 162, 39, 0.5); border-radius: 50px;}
#seller-panel .tophead ul {margin: 0; padding: 0; list-style: none;}
#seller-panel .tophead-right {text-align: right; display: flex;align-items: center; justify-content: end;}
#seller-panel .tophead ul li { list-style: none; padding-left: 7px ; position: relative; padding-right: 0px;}
#seller-panel .tophead ul li:first-child { padding-left: 0px;}
#seller-panel .tophead ul li a {  text-decoration: none ;}
//#seller-panel .tophead1 ul li:last-child {padding-right: 0px;}
#seller-panel .tophead h1 { color: #7E7977; font-weight: 700; font-size: 28px; margin-bottom: 0px; }
#seller-panel .tophead p { color: rgba(101, 112, 89, 1); font-weight: 700; font-size: 16px; margin-bottom: 0px; display: flex; align-items: center; padding-top: 5px; }
#seller-panel .tophead p img { margin-right: 8px; height: 13px; }

#seller-panel .tophead-right .client-loginHead a { background: rgba(183, 65, 14, 0.1); border: 2px solid rgba(183, 65, 14, 0.5); padding-right: 45px !important;
border-radius: 5px 0px 0px 5px; font-weight: 400; font-size: 12px; padding: 5px 20px 5px 20px; margin-bottom: 0px; position: relative; }
#seller-panel .dots { position: absolute; height: 18px; width: 18px; background: #69A227; border-radius: 50%; right: -6px; top: -6px; text-align: center; font-size: 12px; font-weight: 600; color: #ffffff; }
#seller-panel .bell-img { height: 27px; }
#seller-panel .tophead .client-loginHead a{color: #737373;}
#seller-panel .tophead-right .client-loginHead span { font-weight: 700; }
#seller-panel .client-loginHead a img { position: absolute; top: -20px; left: 128px; height: 56px; object-fit: cover; border: 1px solid #D49377; border-radius: 50%; }

#seller-panel .main-box { background: #ffffff; border-radius: 70px 0px 0px 70px; margin: 20px; border-radius: 20px;}

.search-head input { background: transparent; border: 0px; padding-left: 60px;}
.search-head input:focus { outline: none; box-shadow: none; border: 0px; background-color: transparent;}
// input:focus {outline: none; box-shadow: none;}
.search-head { position: relative;}
.search-head img { position: absolute; top: 4px; left: 20px;}
.login-button { border: 1px solid #69A227; border-radius: 50px;}
.login-button .btn-admin:focus { outline: 0px; box-shadow: none; background-color: transparent;}
.login-button .btn-admin { font-size: 12px; font-weight: 400;  color: #69A227 !important; padding: 5px 5px; border-radius: 50px;}

.btn-admin .slider.round { border-radius: 34px;}
.btn-admin .slider.round:before { border-radius: 50%;}
.slider-round-text {padding-left: 5px; color: #000000; font-weight: 400;}
// .page-headpaddingbox { padding: 40px 10px 23px 10px; width: 100%;}
// .pageinnner-padding { padding: 15px 40px 34px 40px; position: relative; }

#seller-panel  .chat-boxcontent h5 {
    font-weight: 300;
    font-size: 12px;
    color: #000000;
    margin-bottom: 0px;
}

#seller-panel .today-contenttext {
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    position: relative;
    z-index: 2;
}

#seller-panel .client-chatimg {
    width: 68px;
    height: 60px;
    margin-right: 5px;
}
#seller-panel  .client-chatimg img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0px 0px;
}





#seller-panel .page-headpaddingbox { padding: 40px 10px 23px 10px; width: 100%;}
#seller-panel .pageinnner-padding { padding: 15px 40px 34px 40px; position: relative; overflow-x: hidden; overflow-y: scroll; height: 605px;}

.side-menu-scroll { height: 648px; overflow-x: hidden; overflow-y: scroll; padding-left: 20px !important;}
.new-scrollleftchat {height: 300px; overflow-x: hidden; overflow-y: scroll;}
// .logo-box { text-align: center; }

// .leftmenu-box {}

// .leftmenu-box ul { margin: 0; padding: 0; }
// .leftmenu-box ul li  { list-style: none; font-weight: 500; font-size: 18px; margin-bottom: 13px; padding: 16px 20px 16px 35px; position: relative;}
// .leftmenu-box ul li a { color: #FFFFFF; text-decoration: none; }
.leftmenu-box ul li a img {padding-right: 10px; height: 25px;}

// .leftmenu-box ul li.active { background: #69A227; border-radius: 50px 0px 0px 50px;}
.leftmenu-box ul li.active::after {position: absolute; content: " "; top: 59px; right: 0px; height: 80px; width: 40px; border-top-right-radius: 80px; -moz-border-top-right-radius: 80px; -webkit-border-top-right-radius: 80px; -webkit-box-shadow: 0 -40px 0 0 #69A227; -moz-box-shadow: 0 -40px 0 0 #69A227; box-shadow: 0 -40px 0 0 #69A227;}
.leftmenu-box ul li.active::before {position: absolute; content: " "; top: -80px; right: 0px; height: 80px; width: 40px; border-bottom-right-radius: 80px; -moz-border-bottom-right-radius: 80px; -webkit-border-bottom-right-radius: 80px; -webkit-box-shadow: 0 40px 0 0 #69A227; -moz-box-shadow: 0 40px 0 0 #69A227; box-shadow: 0 40px 0 0 #69A227;}


.leftmenu-box ul li .point-circle { content: ""; position: absolute; display: block; width: 10px; background-color: #ffffff; height: 10px; border-radius: 50px; top: 25px; left: -14px;} 
.leftmenu-box ul li.active .icon-dashboard:before {content: "\e906";  color: #ffffff;}

.leftmenu-box ul li.active a { color: #ffffff; }
.leftmenu-box ul li.active a i { color: #FFFFFF;}
.leftmenu-box { padding: 20px 0px 20px 20px; margin: 50px 0px;}

#seller-panel .logo-box { background: #353535; border-radius: 0px; padding: 50px 20px; text-align: center; }

// #seller-panel .leftmenu-box { background: #353535; border-radius: 0px; padding: 35px 35px 35px 0px; margin-top: 00px;  }
.side-menu-bg {background: #353535;}
.side-border{height: 6px; width: 100%; background: #69A227;}
#seller-panel .leftmenu-box ul { margin: 0; padding: 0; }
#seller-panel .leftmenu-box ul li  { list-style: none; font-weight: 500; font-size: 18px; margin-bottom: 13px; padding: 16px 20px 16px 35px; position: relative;}
#seller-panel .leftmenu-box ul li a { color: #FFFFFF; text-decoration: none; }
// #seller-panel .leftmenu-box ul li a i { padding-right: 10px; font-size: 18px; }
#seller-panel .leftmenu-box ul li.active { background: #69A227; border-radius: 50px 0px 0px 50px;}
// #seller-panel .leftmenu-box ul li.active::before {  content: ""; position: absolute; display: block; width: 4px; background-color: #69A227; height: 23px; left: 15px; } 
//  #seller-panel .leftmenu-box ul li.active .icon-dashboard:before {  content: "\e906";  color: #FF5501;  }

// #seller-panel .leftmenu-box ul li.active a { color: #69A227; }
// // #seller-panel .leftmenu-box ul li.active a i { color: #69A227 !important;}

#seller-panel .download-appbox { background: #353535; border-radius: 0px;  margin-top: 30px; padding: 27px;}
// // #seller-panel .top-imgdowload { background: url(../images/top-download.svg) right top; background-repeat: no-repeat;  }
// #seller-panel .bottom-imgdowload { padding: 27px 27px; }
#seller-panel .download-appbox h1 { font-weight: 800; font-size: 28px; color: #FFFFFF; margin-bottom: 0px; }
#seller-panel .soical-img { text-align: center; padding-top: 20px; }
#seller-panel .soical-img img { margin-right: 15px; }
// #seller-panel .copy-rightbox { background: #353535; border-radius: 0px; padding: 18px 20px; margin-top: 0px; }
// #seller-panel .copy-rightbox p { font-weight: 400; font-size: 13px; color: #FFFFFF; margin-bottom: 0px; text-align: center; }
#seller-panel .head-text h1 { font-weight: 600; font-size: 23px; color: #69A227; margin-bottom: 0px; border-bottom:  1px solid rgba(0, 0, 0, 0.2); padding-bottom: 20px; } 

#seller-panel .seller-box { background: #FFFFFF; box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1); }
#seller-panel .seller-boxbgimg { background: url(../images/table-topbg.png) right top; background-repeat: no-repeat; }
#seller-panel .seller-bottomimg { background: url(../images/bottom-img2.svg) left bottom; background-repeat: no-repeat; }
#seller-panel .seller-bottomimglast { background: url(../images/bottom-img.svg) right bottom; background-repeat: no-repeat; }
#seller-panel .seller-table thead th { border-bottom:1px solid rgba(101, 101, 101, 0.2);  border-top: none; padding: 18px 0px 18px 20px; line-height: 25px; }
#seller-panel .seller-table td { padding: 15px 0px 0px 15px !important; border-top: none !important; font-weight: 600; font-size: 14px; color: #7E7977; }
#seller-panel .seller-table td .flag-img { width: 45px; }
#seller-panel .seller-table td .flag-img img { width: 100%; height: 100%; object-fit: cover; }
#seller-panel .seller-table .bold-textcontent { font-weight: 700; font-size: 21px; color: #656565; text-align: right; margin-bottom: 0px; line-height: 20px; }

#seller-panel .seller-table thead th:first-child { font-weight: 600; font-size: 20px; color: #7E7977; vertical-align: top; }
#seller-panel .seller-table .last-text  { font-weight: 700; font-size: 33px; color: #656565; vertical-align: top; text-align: right; margin-bottom: 0px; }
#seller-panel .border-top h4 { font-weight: 600; font-size: 16px; color: #FF5501; text-align: center; padding: 10px 10px; }

#seller-panel .sec-table td p { color: #7E7977; font-size: 13px; font-weight: 300; margin-bottom: 0px; line-height: 15px; }
#seller-panel .sec-table td { vertical-align: top !important; }
#seller-panel .sec-table td .user-pics { width: 40px; border-radius: 50%; }
#seller-panel .sec-table td .user-pics img { width: 100%; height: 100%; border-radius: 50%; object-fit: cover; }
#seller-panel .sec-table td .green-badge {  background: rgba(40, 199, 111, 0.2); border-radius: 30px; padding: 4px 10px; color: #28C76F; font-weight: 600; font-size: 12px; }
#seller-panel .sec-table td .red-badge { background: rgba(255, 85, 1, 0.2); border-radius: 30px; font-weight: 600; font-size: 12px; padding: 4px 10px; color: #FF5501; }
#seller-panel .sec-table td:last-child { text-align: center; }



// model======================

#seller-panel .modal { position: fixed; top: 0; left: 0; z-index: 1050; width: 100%; height: 100%; outline: 0; padding: 0 !important; }

#seller-panel .home-pop  .btn-close{ background: url(../images/cancel.png) no-repeat top right;  border: none; height: 25px; width: 25px; filter: invert(1); }
#seller-panel .home-pop  { background:  #404040; }
#seller-panel .home-popcontent ul { margin: 0; padding: 0; padding-top: 20px; }
#seller-panel .home-popcontent ul li { list-style: none; color: #ffffff; margin-bottom: 20px; font-size: 15px; font-weight: 600; }
#seller-panel .home-popcontent ul li a {  color: #ffffff; }
#seller-panel .home-popcontent ul li a i { margin-right: 15px; }
#seller-panel .modal-dialog { position: relative; width: auto; pointer-events: none; }
#seller-panel .qr-popup .modal-dialog { background: none; }
#seller-panel .qr-popup  .btn-close  { display: none; }
#seller-panel .modal.fade.modal-dialog-slideout .modal-dialog {
-webkit-transform: translate(-100%) scale(1); 
transform: translate(-100%) scale(1);  
margin: 0 0 0 auto;
height: 100%;
}
#seller-panel .modal.fade.show.modal-dialog-slideout .modal-dialog {
     /* -webkit-transform: translate(0); */
transform: translate(0); 
display: flex;
align-items: stretch;
-webkit-box-align: stretch;
min-height: 100%;
}
#seller-panel .modal-content {
position: relative;
display: flex;
flex-direction: column;
width: 100%;
pointer-events: auto;
background-color: transparent;
background-clip: padding-box;
border: 0 solid transparent;
border-radius: 0;
outline: 0;
}
#seller-panel .modal-body { position: relative;  flex: 1 1 auto; padding: 1rem 40px;  overflow-y: auto;  overflow-x: hidden; }
#seller-panel .modal-open .modal { overflow-x: hidden;  overflow-y: auto; }
#seller-panel .modal-footer { text-align: left; align-items: flex-start; justify-content: flex-start; padding: 1rem 30px; border-top: 0 solid #eeeef1;
border-bottom-right-radius: 0;  border-bottom-left-radius: 0; }
#seller-panel .modal-header {
     // display: block;
padding: 35px 40px 0px 40px; border-bottom: 0 solid #eeeef1; border-top-left-radius: 0; border-top-right-radius: 0; }

// ============================
#seller-panel .user-headtext  {  position: absolute; top: 19px; }
#seller-panel .user-headtext h1 { border-bottom: none; padding-bottom: 0px; }
#seller-panel .user-tabs { padding-bottom: 15px; }
// #seller-panel .cost-tab { background: rgba(105, 162, 39, 0.15); padding: 10px; }
#seller-panel .cost-tab .nav-link  { padding: 5px 20px !important; font-size: 12px !important; padding: 10px;}
#seller-panel .cost-tab .nav-link.active  { font-size: 12px !important; }
#seller-panel .user-tabs .nav-link.active {  background: #fff; color: #69A227; border-radius: 50px !important; font-weight: 600; font-size: 14px; border: none; }
#seller-panel .user-tabs .nav-link { font-weight: 700; font-size: 12px; color: #69A227; padding: 8px 30px; }
#seller-panel .user-tabs  .nav-link:hover { border: none; }
#seller-panel .user-tabs  .nav-link { border: none; }
#seller-panel .user-tabs .nav-item { background: rgba(105, 162, 39, 0.15); padding: 5px;}
#seller-panel .user-tabs .nav-item:last-child { border-top-right-radius: 50px; border-bottom-right-radius: 50px; }
#seller-panel .user-tabs .nav-item:first-child {  border-top-left-radius: 50px; border-bottom-left-radius: 50px; }

#seller-panel .serch-input { background: #F1F1F1; border: 1px solid #CACACA; border-radius: 5px; font-style: italic; font-weight: 500; font-size: 13px;
padding: 17px; padding-left: 45px !important; position: relative; text-overflow: ellipsis; }
#seller-panel .serch-input:focus { background: #F1F1F1; border: 1px solid #CACACA; border-radius: 5px;  box-shadow: none; outline: none; }
#seller-panel .serch-input::placeholder { color: #ACACAC; }
#seller-panel .filter-box { background: #FFFFFF; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05); border-radius: 3px; text-align: center;  
width: 36px; height: 36px; line-height: 36px; float: right;}
#seller-panel .search-img { position: absolute; top: 10px; left: 28px; }

.buy-table {background: #FFFFFF;}
.buy-table td { font-weight: 400; font-size: 12px; color: #374151; border-top: none; vertical-align: middle; padding-left: 30px; }
.buy-table thead th { border-bottom: 1px solid #F7F7F7; font-weight: 400; font-size: 12px; color: #374151; padding-left: 30px; vertical-align: middle; border-top: none; }
#seller-panel .standard-btn { background: rgba(105, 162, 39, 0.20); border-radius: 0px; color: #69A227; font-weight: 700; font-size: 10px; padding: 7px 10px; margin-bottom: 0px;}
.details-btn { border: 1px solid #FF5501; color: #FF5501; font-weight: 700; font-size: 11px; padding: 3px 12px; border-radius: 5px; }
.details-btn:hover { background: #FF5501; color: #FFFFFF; border: 1px solid #FF5501; }
.buy-table tr th:first-child { color: #1F2937; font-weight: 700;  }
.premium-btn { background: rgba(255, 85, 1, 0.15); border-radius: 9px;font-weight: 700; font-size: 10px; padding: 7px 10px; color: #FF5501; }
.buy-table tr td:nth-child(5) { color: #374151; font-weight: 500; }
.buy-table tr td:nth-child(4) { color: #4B5563; }

.buy-table tr:nth-child(2) th:first-child { border-top-left-radius: 5px !important; }
.buy-table tr:nth-child(2) th:last-child { border-top-right-radius: 5px !important; }
.head-td { background: #F7F7F7 !important; color: #FF5501 !important; font-weight: 500; }

// 
#seller-panel .filter-popup .modal-dialog { background: #FFFFFF; }
#seller-panel .filter-popup.fade.modal-dialog-slideout .modal-dialog {
       -webkit-transform: translate(100%) scale(1) !important; 
        transform: translate(100%) scale(1) !important; 
       margin: 0 0 0 auto;
       height: 100%;
}  
#seller-panel .filter-popup.fade.show.modal-dialog-slideout .modal-dialog {
    /* -webkit-transform: translate(0); */
    transform: translate(0) !important; 
    display: flex;
    align-items: stretch;
    -webkit-box-align: stretch;
    min-height: 100%;
}
#seller-panel .filter-popup .btn-close{ background: url(../images/cancel.png) no-repeat top right; border: none; height: 25px; width: 25px; }
#seller-panel .pop-head h1 { color: #FF5501; font-weight: 600; font-size: 22px; margin-bottom: 0px; }

// Login
#seller-panel .login-bg{ background: url(../images/login-bg.png); background-repeat: no-repeat;  background-position: center; background-size: cover;
width: 100%; height: 120vh; }
#seller-panel .login-form { background: rgba(255, 255, 255, 0.5); border-radius: 20px;   padding: 45px 20px; }
#seller-panel .login-form h1 { font-weight: 500; color: #000000;  font-size: 18px; margin-bottom: 0px; padding-top: 40px; }

#seller-panel .login-form input { background: #F0EFFF; border-radius: 8px; padding: 28px 21px; border: none;  color: #FF5501; font-size: 16px; font-weight: 400;  margin-top: 35px; }
#seller-panel .login-form input::placeholder { color: #FF5501; }
#seller-panel .login-form input:focus { border: none; outline: none; box-shadow: none; background: #F0EFFF; border-radius: 8px; }
#seller-panel .verfication input:last-child { margin-right: 0px; }
#seller-panel .verfication input { width: 41px; margin-right: 15px; padding: 17px 21px; }

#seller-panel .login-btn { background: #FF5501; box-shadow: 0px 4px 61px rgba(77, 71, 195, 0.4); border-radius: 9px; color: #FFFFFF; font-size: 16px; font-weight: 500; padding: 13px 20px; }
#seller-panel .login-btn:hover { color: #ffffff; text-decoration: none; }
#seller-panel .login-form a { text-decoration: none; }
#seller-panel .login-form p { font-weight: 600; font-size: 15px; color: #FF5501; margin-top: 0px; text-align: right; margin-bottom: 0px; }
#seller-panel .login-form p a { color: #FF5501; }

// details


#seller-panel .add-text { top: 0px !important; }
#seller-panel .add-form { background: #ffffff; border-radius: 8px; border: 1px solid #ced4da; padding: 20px 20px;  font-weight: 400; font-size: 14px; }
#seller-panel .add-form:focus { background: #ffffff; border-radius: 8px; border: 1px solid #ced4da; outline: none; box-shadow: none;  padding: 20px 20px;}
#seller-panel .add-select { background: #ffffff; border-radius: 8px; border: 1px solid #ced4da; height: 42px; }
#seller-panel .add-select:focus { background: #ffffff; border-radius: 8px; border: 1px solid #ced4da; outline: none; box-shadow: none; }
#seller-panel .buy-table img { height: 18px; margin-right: 3px; }

// 
#seller-panel .padding-rightnone { padding: 15px 45px 34px 0px; }
#seller-panel .content-menubox { padding-right: 9px;  margin-top: 35px;  }
#seller-panel .content-menubox ul { margin: 0; padding: 0; }
#seller-panel .content-menubox ul li  { list-style: none; font-weight: 600; font-size: 15px; margin-bottom: 13px; padding: 8px 10px 8px 28px; }
#seller-panel .content-menubox ul li a { color: #353535; text-decoration: none; }
#seller-panel .content-menubox ul li a i { padding-right: 10px; font-size: 18px; }
#seller-panel .content-menubox ul li.active { background: #69A227; border-radius: 0px 20px 20px 0px;  }
#seller-panel .content-menubox ul li.active a { color: #FFFFFF !important;}
#seller-panel .content-menubox ul li.active::before {  content: ""; position: absolute; display: block; width: 4px; background-color: #FFFFFF;
height: 23px; left: 15px; border-radius: 20px; } 
#seller-panel .right-contentbox { padding-left: 15px; }
#seller-panel .head-content p { color: #FF5501; font-weight: 600; font-size: 18px; margin-bottom: 0px; }
#seller-panel .contentimg-box { width: 100%; height: 137px; }
#seller-panel .contentimg-box img { width: 100%; height: 100%; object-fit: cover; }

.contentimg-box { width: 100%; height: 137px; }
.contentimg-box .box-imgnew {  object-fit: fill; width: 100%; height: 100%;}

#seller-panel .upload-btn-wrapper {position: relative; overflow: hidden; display: block;}
#seller-panel .uploadBtn { background: #69A227; color: #FFFFFF; outline: none; text-align: center; font-size: 13px;
padding: 4px 18px; border: none; font-weight: 600; }
#seller-panel .upload-btn-wrapper input[type=file] {font-size: 100px; position: absolute; left: -124px; top: 0; opacity: 0; cursor: pointer; height: 500px;
font-size: 20px; width: 900px;}
#seller-panel .lenth-box { margin-bottom: 0px; display: flex; align-items: center; font-weight: 600; font-size: 14px; color: #7E7977;
background: rgba(255, 85, 1, 0.1); border: 1px solid #FF5501; padding: 10px 15px; }
#seller-panel .lenth-box  img { margin-right: 13px; }
#seller-panel .content-input { text-overflow: ellipsis;  overflow: hidden; white-space: nowrap; border: 1px solid #D8D6DE; border-radius: 5px; font-weight: 500; font-size: 17px; color: #B4B4B4; padding: 23px 20px;
background: #F7F7F7; }
#seller-panel .content-input:focus { border: 1px solid #D8D6DE; border-radius: 5px; background: #F7F7F7; outline: none; box-shadow: none; }
#seller-panel .content-input::placeholder { color: #B4B4B4; }

#seller-panel .right-contentbox h6 { font-weight: 400; font-size: 18px; color: #373737; margin-bottom: 0px; display: flex; align-items: center; padding-left: 35px; margin-top: 15px; }
#seller-panel .right-contentbox h6 span { width: 8px !important; height: 8px !important; border-radius: 50%; background: #373737; display: block; margin-right: 10px; }
#seller-panel .bottom-imgcont { width: 139px; height: 93px; margin-bottom: 20px; }
#seller-panel .bottom-imgcont img { width: 100%; height: 100%; object-fit: cover; }
#seller-panel .cont-manage { margin-right: 15px; display: inline-block; }
#seller-panel .cont-imgmain .cont-manage:last-child { margin-right: 0px;}

//============ 
#seller-panel .sparess-textbox { background: rgba(84, 84, 84, 0.05); padding: 18px 30px; }
#seller-panel .sparess-textbox h1 { color: #9F9F9F; margin-bottom: 0px; font-weight: 500; font-size: 24px; line-height: 37px; }
#seller-panel .sparess-textbox .orange-textsparess { font-weight: 700; color: #FF5501; }
#seller-panel .sparess-textbox .balck-textsparess { color: #000000; font-weight: 600; }
#seller-panel .sparess-textbox h2 { color: #7D7D7D; font-weight: 600; font-size: 19px; margin-bottom: 0px; line-height: 32px; }
#seller-panel .sparess-textbox h2 span { font-weight: 700; font-size: 20px; }
#seller-panel .sparess-textbox h3 { font-weight: 700; font-size: 20px; line-height: 24px; text-align: center; color: #FF5501; margin-bottom: 0px;}

//================
#seller-panel .login-form h6 { font-weight: 400; font-size: 14px; margin-bottom: 0px; }
#seller-panel .login-form h6 a { color: #000000; }
#seller-panel .new-loginbtn { background: rgba(255, 85, 1, 0.7); border-radius: 9px; font-weight: 400; font-size: 13px; color: #FFFFFF; padding: 7px 26px; }
#seller-panel .new-loginbtn:hover { color: #FFFFFF; }
#seller-panel .new-dots { background: #FF5501 !important; }

#seller-panel .avail-Offer { background: linear-gradient(90.14deg, rgba(53, 53, 53, 0.62) 0.09%, #FF5501 99.88%); padding: 30px 10px; }
#seller-panel .avail-Offer h1 { font-weight: 300; font-size: 22px; color: #FFFFFF; margin-bottom: 0px; }
#seller-panel .avail-Offer h1 span { font-weight: 700; }
#seller-panel .avail-Offer p  {  font-weight: 300; font-size: 16px; color: #FFFFFF; margin-bottom: 0px; padding-top: 8px; }
#seller-panel .white-btn { background: #FFFFFF; border-radius: 5px; font-weight: 700; font-size: 20px; color: #FF5501; padding: 7px 30px;}
#seller-panel .white-btn:hover { color: #FF5501; }
#seller-panel .posttable { background: #FFFFFF; border-radius: 10px; }
#seller-panel .posttable th { border-top: none; padding: 7px 7px 7px 20px;}
#seller-panel .posttable thead { background: #FFFFFF; box-shadow: 0px 1px 25px 2px rgba(0, 0, 0, 0.1); border-radius: 10px 10px 0px 0px; }
#seller-panel .posttable thead th { border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom: none; font-weight: 500; font-size: 18px; 
color: #7E7977; }
#seller-panel .eyes-imgsparess { height: 12px; margin-left: 5px; }
#seller-panel .posttable td { font-weight: 400; font-size: 15px; color: #7E7977; vertical-align: middle; border-top: 1px solid #F0F0F0; padding: 7px 7px 7px 20px; }
// #seller-panel .chat-table { border: 1px solid; border-radius: 10px !important;}
#seller-panel .chat-table td, .chat-table th { border-top: none; }
#seller-panel .chat-table thead { background: #FFFFFF; box-shadow: 0px 1px 25px 2px rgba(0, 0, 0, 0.1); border-radius: 10px 10px 0px 0px; }
#seller-panel .chat-table { background: #FFFFFF; border-radius: 10px; }
#seller-panel .chat-table thead th { border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom: none; font-weight: 500; font-size: 18px; color: #7E7977; vertical-align: middle; padding: 5px 10px 5px 10px;  }
#seller-panel .chat-table thead th:last-child { text-align: right; }
#seller-panel .chat-table td h2 { margin-bottom: 8px; font-weight: 600; font-size: 16px; color: #B9B9B9; }
#seller-panel .chat-table td .typing-text { font-weight: 500; font-size: 12px; color: #11A242; padding-top: 10px; }
#seller-panel .chat-table td:last-child { text-align: right; }
#seller-panel .chat-table td .fire-text { font-weight: 500; font-size: 11px; color: #AAACAD; }

#seller-panel .chat-table td { font-weight: 300; font-size: 12px; color: #AAACAD; vertical-align: top; padding: 5px 7px 5px 7px; position: relative; border-top: 1px solid #F0F0F0; }
#seller-panel .chat-table thead th p { margin-bottom: 0px; font-weight: 500; font-size: 28px; color: #000000; }
#seller-panel .chat-table td  .badge-red  { background: #B70E0E; width: 18px; height: 18px; border-radius: 50%; font-weight: 800; font-size: 12px; color: #FFFFFF; margin-top: 5px;  }
#seller-panel .sparess-user { width: 45px; height: 45px; border-radius: 50%; position: relative; }
#seller-panel .sparess-user img { width: 100%; height: 100%; object-fit: cover;  border-radius: 50%;}
#seller-panel .green-dots { width: 10px; height: 10px; line-height: 10px; border-radius: 50%; background: #11A242; display: block; position: absolute;
left: 44px; bottom: 11px; }
#seller-panel .chat-table td h6 { font-weight: 300; font-size: 15px; color: #69A227; margin-bottom: 0px; }
#seller-panel .chat-table td h4 { font-weight: 800; font-size: 12px; color: #000000; margin-bottom: 0px; }

#seller-panel .new-chattable td { vertical-align: middle;  padding: 11px 9px 10px 15px; }
#seller-panel .new-chattable td:last-child { text-align: center; }
#seller-panel .my-Adsbox { background: #EBF2E8; border: 1px solid #FFFFFF; border-radius: 10px; padding: 15px; min-height: 332px;}
#seller-panel .my-Adsbox h4 { font-weight: 600; font-size: 24px; color: #FF5501; margin-bottom: 0px; }
#seller-panel .my-Adsbox p { background: #F7F7F7; width: 40px; height: 40px; border: 1px solid #11A242; border-radius: 50%; text-align: center; 
line-height: 40px; margin-bottom: 0px; font-weight: 600; font-size: 26px; color: #11A242; }
#seller-panel .my-Adsbox h2 { font-weight: 400; font-size: 14px; color: #2B2A2A; margin-bottom: 0px; }
#seller-panel .my-Adsbox h3 { font-weight: 600; font-size: 16px; color: #606060; margin-bottom: 0px; }

#seller-panel .sparessborder-btn { border: 1px solid #69A227; border-radius: 0px; font-weight: 600; font-size: 14px; color: #69A227; padding: 6px 10px; }
#seller-panel .sparessfill-btn { background: #69A227; border-radius: 0px; font-weight: 600; font-size: 14px; color: #FFFFFF; padding: 6px 10px; } 
#seller-panel .sparessborder-btn:hover { color: #69A227; }
#seller-panel .sparessfill-btn:hover { color: #ffffff; }
#seller-panel .leftmenu-box ul li.active .icon-new-dashbord:before { color: #69A227; }
#seller-panel .buy-msgimg { width: 30px; height: 30px; }
#seller-panel .buy-msgimg img { width: 100%; height: 100%; object-fit: cover; }

#seller-panel .profile-editbox {  background: url(../images/profile-editbg.png); background-repeat: no-repeat;  background-position: center;
background-size: cover; border-radius: 180px 0px 0px 0px; min-height: 200px; position: relative; }
#seller-panel .edit-upload { position: absolute; right: 10px; bottom: 10px; }
#seller-panel .edit-form { padding-left: 40px; }
#seller-panel .edit-userpic { width: 130px; height: 130px; border-radius: 50%; margin-top: -50px;  border: 2px solid #FFFFFF; box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);}
#seller-panel .edit-label { font-weight: 400;  font-size: 14px;  color: #000000; }
#seller-panel .edit-userpic img { width: 100%; height: 100%; object-fit: cover; border-radius: 50%; }
#seller-panel .edit-form h1 { font-weight: 500; font-size: 22px; color: #7E7977; margin-bottom: 0px; padding-top: 15px; }
#seller-panel .edit-form p { font-weight: 400; font-size: 16px;  color: #7E7977; margin-bottom: 0px; }
#seller-panel .edit-input { border: 1px solid rgba(126, 121, 119, 0.5); border-radius: 0px; background: transparent; }
#seller-panel .edit-input:focus { border: 1px solid rgba(126, 121, 119, 0.5); border-radius: 5px; background: transparent; outline: none; box-shadow: none; }
  
#seller-panel .new-save { font-size: 16px;  padding: 8px 45px; }
#seller-panel .new-cancel { font-size: 16px;  padding: 8px 45px; background: #FFFFFF; }
#seller-panel .edit-userallow {  background: rgba(105, 162, 39, 0.20); border-radius: 50%;  border: none; padding: 6px; width: 25px; height: 25px; line-height: 6px; }
#seller-panel .new-upload { position: absolute; right: 0px; bottom: 27px; }
#seller-panel .paid-box { background: #EAC8B9;  border-radius: 20px; }
//#seller-panel .edit-userallow img { width: 100%; height: 100%; object-fit: cover; }

#seller-panel .paid-bg {  background: url(../images/paid-bottom.png) bottom left; background-repeat: no-repeat;  }
#seller-panel .paid-box h1 { font-weight: 400; font-size: 32px; color: #F5F5F5; margin-bottom: 0px; padding-top: 25px; }
#seller-panel .paid-box p { color: #FF5501; font-weight: 600; font-size: 35px; margin-bottom: 0px; padding-bottom: 16px; }
#seller-panel .paid-content h1 { font-weight: 600;  font-size: 18px;  color: #FF5501; margin-bottom: 0px; }
#seller-panel .paid-content p { font-weight: 500; font-size: 13px;  color: #969696; margin-bottom: 0px; padding-top: 10px; line-height: 22px; }
#seller-panel .left-paidbox { background: #FFAA80; box-shadow: 0px 8px 20px 15px rgba(0, 0, 0, 0.05); }
#seller-panel .left-paidbox h1 { display: flex; align-items: center; border-bottom: 1px solid #F2F2F2; font-weight: 400; font-size: 18px; padding: 10px 0px; justify-content: center; color: #FFFFFF; margin-bottom: 0px;  }
#seller-panel .left-paidbox h1 img { margin-right: 5px; }
#seller-panel .left-paidboxpadding { padding: 15px; }
#seller-panel .paid-textbox { display: flex; align-items: center; justify-content: space-between; margin-bottom: 8px; }
#seller-panel .paid-textbox p { font-weight: 400; font-size: 12px; color: #FFFFFF;  margin-bottom: 0px; margin-right: 25px; }
#seller-panel .paid-textbox h6 { margin-bottom: 0px; font-weight: 400; font-size: 16px; color: #FFFFFF; }

#seller-panel .right-paidbox { background: #FFFFFF; }
#seller-panel .right-paidbox p { color: #FF5501; }
#seller-panel .right-paidbox h6 { color: #FF5501; }
#seller-panel .right-paidbox h1 { color: #FF5501;  }
#seller-panel .new-whitebtn { font-size: 14px; padding: 6px 25px; }
#seller-panel .feature-includes { background: #FFFFFF; border-radius: 10px; padding: 15px; margin-top: -95px; }

//posting===========

 #seller-panel .posting-tab .nav-tabs { border-bottom: 1px solid #E2E2E2;}
#seller-panel .posting-tab .nav-link.active { font-weight: 600; font-size: 22px; color: #FF5501; background: none; border-bottom: 2px solid #FF5501; }
#seller-panel .posting-tab .nav-link.active:hover { border-bottom: 2px solid #FF5501 !important; }
#seller-panel .posting-tab .nav-link:hover { border: none; }
#seller-panel .posting-tab .nav-link { border: none; font-weight: 400; font-size: 17px; color: #FF5501; padding: 15px 20px 10px 0px; margin-right: 50px; }

#seller-panel .attachments-text { background: rgba(255, 255, 255, 0.5); border: 1px solid #E2E2E2; border-radius: 5px; margin-bottom: 0px;
padding: 5px; font-weight: 400;  font-size: 12px; color: #8D8D8D; display: flex; align-items: center; line-height: 25px; }
#seller-panel .attachments-text img { margin-right: 7px;}
#seller-panel .posting-box { background: #FFFFFF;  border-radius: 10px;  padding: 5px 15px;}
#seller-panel .post-input { border: 1px solid #C2C2C2; border-radius: 5px; font-weight: 400; font-size: 14px; color: #9F8F88; padding: 0.375rem 0.95rem; }
#seller-panel .post-input:focus { border: 1px solid #C2C2C2; border-radius: 5px; outline: none; box-shadow: none; }
#seller-panel .post-input::placeholder { color: #9F8F88; }
#seller-panel .post-label { font-weight: 600; font-size: 14px; color: #7E7977; }
#seller-panel .small-text { font-weight: 400; font-size: 12px; color: #7E7977; }
#seller-panel .browser-post { background: #F8F8F8; border: 1px dashed #7B7B7B; border-radius: 4px;  padding: 5px 9px; display: flex;
justify-content: center;  flex-direction: column; min-height: 120px;}
#seller-panel .browser-post p { font-weight: 600; font-size: 14px; color: #7E7977; margin-bottom: 0px; padding-top: 5px; }
#seller-panel .post-clickbtn { background: none; border: none; color: #7582FA; font-weight: 400px !important; font-size: 10px !important; padding: 0px !important; }l
#seller-panel .new-padding { padding: 5px 8px; }
#seller-panel .post-imgbox { width: 100%; height: 150px; }
#seller-panel .post-imgbox img { width: 100%; height: 100%; object-fit: cover; border-radius: 4px; }

#seller-panel .posting-box h6 { font-weight: 500; font-size: 15px; color: #969696; margin-bottom: 0px; }
#seller-panel .check-label { font-weight: 400 !important; font-size: 12px !important; color: #6E6B7B !important; }
#seller-panel .online-text { font-weight: 400; font-size: 12px; color: #737373; }
#seller-panel .remaining-box { background: #FFFFFF; border-radius: 10px; padding: 25px 15px; }
#seller-panel .remaining-box h1 { font-weight: 700; font-size: 20px;  color: #9F8F88; margin-bottom: 0px; }
#seller-panel .remaining-box h6 { margin-bottom: 0px; font-weight: 500; font-size: 14px;  color: #9F8F88; margin-bottom: 0px; padding-top: 5px; }
#seller-panel .remaining-box h5 { font-weight: 700; font-size: 16px; color: #9F8F88; margin-bottom: 0px; margin-top: 1.5rem; }
#seller-panel .remaining-box h3 { font-weight: 400; font-size: 24px; color: #9F8F88; margin-bottom: 0px; }
#seller-panel .posting-table thead th  {color: var(--light-typography-color-heading-text, #4B465C) !important;
    font-size: 12px;
    font-weight: 600 !important;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    // padding-left: 0px;
}
#seller-panel .posting-table td, .table th { border-top: none;} 
#seller-panel .posting-table td { font-weight: 400; font-size: 14px;  color: #4B465C; vertical-align: middle; padding: 0px 12px; border-bottom: 1px solid #DBDADE;}
// #seller-panel .posting-table td:last-child { text-align: right; }
// #seller-panel .posting-table th:last-child { text-align: right; }
#seller-panel .update-btn { background: rgba(255, 85, 1, 0.3);  border-radius: 5px; font-weight: 500; font-size: 15px; color: #FF5501; padding: 5px 15px; }
#seller-panel .update-btn:hover { color: #FF5501;  }
#seller-panel .table-imgbox { display: flex; align-items: center; }
//#seller-panel .table-imgbox img { margin-right: 8px; }
#seller-panel .table-imgbox p { font-weight: 600; font-size: 14px; margin-bottom: 0px; line-height: 20px; color: var(--light-typography-color-body-text, #4B465C);}
#seller-panel .table-firstimg { width: 60px; height: 60px; margin-right: 7px; }
#seller-panel .table-firstimg img { width: 100%; height: 100%; object-fit: cover; border-radius: 0px; }
#seller-panel .forth-tablepost { background: #FFFFFF; border-radius: 10px; padding: 15px 0px; }
#seller-panel .forth-tablepost h1 {  font-weight: 500; font-size: 15px; color: #969696;  margin-bottom: 0px;}
#seller-panel .forth-tablepost h1 span { font-weight: 700; font-size: 15px;   color: #969696; padding-right: 20px; }
#seller-panel .product-tab-head h2 { font-weight: 600; font-size: 18px; color: #69A227; margin-bottom: 0px; }

// ======
#seller-panel .user-sellernewbox  ul { margin: 0; background: #FFFFFF; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05); border-radius: 50px; padding: 8px 4px; }
#seller-panel .user-sellernewbox ul li { display: inline; list-style: none; padding: 8px 22px; font-weight: 700; font-size: 17px;   }
#seller-panel .user-sellernewbox ul li a { color: #FF5501; text-decoration: none; }
#seller-panel .user-sellernewbox ul li.active  { background: #FF5501; border-radius: 50px; }
#seller-panel .user-sellernewbox ul li.active a { color: #ffffff; }

#seller-panel .new-seller ul { margin: 0; padding: 0; }
#seller-panel .new-seller ul li { display: inline-block; list-style: none; background: #FFFFFF;  padding: 3px; }    
    //  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);}
#seller-panel .new-seller { float: right;}
#seller-panel .new-seller ul li:first-child { border-top-left-radius: 50px; border-bottom-left-radius: 50px; }
#seller-panel .new-seller ul li:last-child { border-top-right-radius: 50px; border-bottom-right-radius: 50px; }
#seller-panel .new-seller  ul li button {  font-weight: 700; font-size: 17px;  color: #FF5501; padding: 5px 20px; }
#seller-panel .new-seller  ul li button:hover {  color: #FF5501;  }
#seller-panel .new-seller  ul li button.active {  background: #FF5501; border-radius: 50px; color: #FFFFFF; }
#seller-panel .new-seller  ul li button:focus {  box-shadow: none; outline: none; } 

// new-podtting tab
#seller-panel .new-posttingtab ul { margin: 0; padding: 0; border-bottom: 1px solid #E2E2E2; padding-bottom: 12px; }
#seller-panel .new-posttingtab ul li { list-style: none; display: inline; font-weight: 400;  font-size: 17px; margin-right: 50px; padding: 0px 14px 14px 0px; }
#seller-panel .new-posttingtab ul li a {  color: #FF5501;  text-decoration: none;  }
#seller-panel .new-posttingtab ul li.active { font-weight: 600; font-size: 22px; border-bottom: 2px solid #FF5501; }


//=======================

// #seller-panel .switch { position: relative;  display: inline-block;  width: 40px; height: 24px; margin-bottom: 0px; }
// #seller-panel .switch input {  opacity: 0;  width: 0; height: 0; }
// #seller-panel .slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ffffff; 
// -webkit-transition: .4s;  transition: .4s; }
// #seller-panel .slider:before { position: absolute; content: ""; height: 20px; width: 20px; left: 3px; bottom: 2px; background-color: #cccccc; 
// -webkit-transition: .4s;  transition: .4s;}
// #seller-panel input:checked + .slider { background-color: #6B6B6B; }
// #seller-panel input:focus + .slider { box-shadow: 0 0 1px #6B6B6B; }
// #seller-panel input:checked + .slider:before { -webkit-transform: translateX(26px); -ms-transform: translateX(26px);  transform: translateX(26px);  left: -8px; }
// /* Rounded sliders */
// #seller-panel .slider.round { border-radius: 34px; }
// #seller-panel .slider.round:before { border-radius: 50%; }

#seller-panel .advertisement-box { background: linear-gradient(105.49deg, rgba(183, 65, 14, 0.35) 11.18%, #EAC8B9 66.99%); border-radius: 10px; }
#seller-panel .feature-includes h1 { font-weight: 600; font-size: 18px; color: #FF5501; margin-bottom: 0px; text-align: center; }
#seller-panel .feature-includes ul { margin: 0; padding: 0; }
#seller-panel .feature-includes ul  li {  list-style: none; font-weight: 500; font-size: 11px; color: #838383; margin-top: 15px; background: url(../images/star-add.svg); background-repeat: no-repeat; padding-left: 30px; }
#seller-panel .servicespadding-left { padding-left: 30px; }
#seller-panel .settingpadding-left { padding-left: 40px; }
#seller-panel .settingpadding-left h1 { font-weight: 700;  font-size: 27px;  color: #69A227; margin-bottom: 0px; padding-bottom: 15px; text-align: center; }
// #seller-panel .settingpadding-left h1 {}

#seller-panel .enquiry-box h6 { font-weight: 600;  font-size: 18px;  color: #B9B9B9; margin-bottom: 0px; }
#seller-panel .enquiry-box h6 span { font-weight: normal; }
#seller-panel .enabled-text { font-weight: 600;  font-size: 12px;   color: #FF5501; }
#seller-panel .enquiry-box { padding: 25px 0px; border-bottom: 1px solid rgba(0, 0, 0, 0.13); }
#seller-panel .desk-from { border-radius: 10px;
    background: linear-gradient(117deg, #69A227 7.8%, #B4CD20 89.39%); margin-top: 20px; }

#seller-panel .desk-formbg { background: url(../images/new-contact-bg.png) right bottom; background-repeat: no-repeat; padding: 45px 15px 45px 45px; border-radius: 10px; }
#seller-panel .desk-formbg-2 { padding: 45px !important;}
#seller-panel .allover-padding {  padding: 20px 20px 20px 10px !important; }
#seller-panel .desk-from h6 { font-weight: 700;  font-size: 28px;  color: #ffffff;  margin-bottom: 0px;}
#seller-panel .desk-from p { font-weight: 400; font-size: 18px;  color: #fff; margin-bottom: 0px; padding-top: 15px; line-height: 22px; }
#seller-panel .contact-box { font-weight: 600;  font-size: 16px;  color: #ffffff; align-items: center; display: flex; margin-top: 35px;}
#seller-panel .contact-box img { margin-right: 15px; }
#seller-panel .form-contactsetting { background: rgba(255, 255, 255, 0.4);  border: 1px solid #FFFFFF;   backdrop-filter: blur(5px); border-radius: 0px; padding: 25px 25px; }
#seller-panel .desk-from h4 { display: flex; align-items: center; margin-top: 55px; }
#seller-panel .desk-from h4 img { margin-right: 13px; }

#seller-panel .seller-table td:first-child { width: 63px; }

#seller-panel .newupload-btn-wrapper .uploadBtn {  width:100%; text-align: center !important;}

#seller-panel .user-drop .dropdown-toggle::after { border-top: none; border-right: none; border-bottom: none; border-left: none; }
// #seller-panel .user-drop button:focus { }
#seller-panel .user-drop button { padding: 0px; border-radius: 50px;}
// #seller-panel .new-usermainbox { height: 50px !important; width: 50px !important; border-radius: 50px !important;}
// #seller-panel .user-drop button img { border: none !important; border-radius: 50%;}
#seller-panel .user-drop .dropdown-item img { margin-right: 9px;}
#seller-panel .user-drop .dropdown-item { border-bottom: 0.5px solid #C6C6C6; font-weight: 400; font-size: 14px; color: #AAAAAA; padding: 0.8rem 0.7rem; }
#seller-panel .user-drop .dropdown-item:last-child { border-bottom: none; }
#seller-panel .user-drop .dropdown-menu { padding: 0px 0px; border: none; margin-top: 17px; }

#seller-panel .user-drop .dropdown-menu:after { bottom: 100%; left: 89%; border: solid transparent; content: ""; height: 0; width: 0; position: absolute; pointer-events: none; border-color: rgba(136, 183, 213, 0); border-bottom-color: #fff; border-width: 12px; margin-left: -30px;}
#seller-panel .user-drop .dropdown-toggle::after { border-top: none; border-right: none; border-bottom: none; border-left: none; }
#seller-panel .user-drop button:focus { box-shadow: none; }
// #seller-panel .user-drop button { padding: 0px;  }
#seller-panel .user-drop button img { border: 0px; border-radius: 50%; width: 50px; height: 50px; object-fit: cover;}
#seller-panel .user-drop .dropdown-item img { margin-right: 9px; }
#seller-panel .user-drop .dropdown-item { border-bottom: 0.5px solid #C6C6C6; font-weight: 400; font-size: 14px; color: #AAAAAA; padding: 0.8rem 0.7rem; }
#seller-panel .user-drop .dropdown-item:last-child { border-bottom: none; }
#seller-panel .user-drop .dropdown-menu { padding: 0px 0px; border: none; margin-top: 17px; }

#seller-panel .user-drop .dropdown-menu:after { bottom: 100%; left: 89%; border: solid transparent;  content: ""; height: 0; width: 0; position: absolute; pointer-events: none; border-color: rgba(136, 183, 213, 0);  border-bottom-color: #fff; border-width: 12px; margin-left: -30px;}

#seller-panel .dash-tab-1 { border: 1px solid #F0F0F0; border-radius: 10px; height: 332px;}
#seller-panel .dash-tab-1 .nav-tabs {justify-content: space-evenly; background-color:#ffffff; border-radius: 10px 10px 0px 0px;}
#seller-panel .dash-tab-1 .nav-tabs .nav-link.active{border: none; border-bottom: 2px solid #69A227; padding: 9px;}
// .nav-tabs .nav-link.active::before {border-radius: 10px;}
#seller-panel .dash-tab-1 .nav-tabs .nav-link{border: none;  padding: 12px 5px;}
#seller-panel .dash-tab-1 .nav-link:hover {border: none;}
#seller-panel .dash-tab-1 .nav-tabs .nav-link.active h1{color: #69A227; font-size: 20px; font-weight: 600;}
#seller-panel .dash-tab-1 .nav-tabs .nav-link h1{font-size: 16px; color: #A4A4A4; font-weight: 400; margin: 0px;}
#seller-panel .dash-tab-1 .nav-tabs .nav-link.active span{background: rgba(164, 164, 164, 0.2); border-radius: 50%; padding: 3px 5px; font-size: 11px; font-weight: 800; color: #69A227;}
#seller-panel .dash-tab-1 .nav-tabs .nav-link span{background: rgba(164, 164, 164, 0.2); border-radius: 50%; padding: 3px 5px; font-size: 10px; font-weight: 800; color: #A4A4A4;}

#seller-panel .my-Adsbox { background: #EBF2E8; border: 1px solid #FFFFFF; border-radius: 10px; min-height: 332px;}
#seller-panel .my-Adsbox .nav-tabs{background: #EBF2E8 !important; border-bottom: 0px;}
#seller-panel .my-Adsbox .nav-tabs .nav-link.active {background: transparent;}
#seller-panel .my-Adsbox h4 { font-weight: 600; font-size: 24px; color: #FF5501; margin-bottom: 0px; }
#seller-panel .my-Adsbox p { background: #F7F7F7; width: 40px; height: 40px; border: 1px solid #11A242; border-radius: 50%; text-align: center; line-height: 40px; margin-bottom: 0px; font-weight: 600; font-size: 26px; color: #11A242; }
#seller-panel .my-Adsbox h2 { font-weight: 400; font-size: 14px; color: #2B2A2A; margin-bottom: 0px; }
#seller-panel .my-Adsbox h3 { font-weight: 600; font-size: 16px; color: #606060; margin-bottom: 0px; }

#seller-panel .btn-deflt { background: rgba(255, 85, 1, 0.3);color: #FF5501 !important;font-size: 14px;padding: 5px 10px; margin-bottom: 4px; margin-top: 2px;}
#seller-panel .posttable-new{background: none;}
#seller-panel .posttable-new thead{background: none; box-shadow: none;}
#seller-panel .posttable-new thead th{color: #69A227; border-bottom: 1px solid #E2E2E2; font-size: 16px; margin-bottom: 4px;}
#seller-panel .posttable-new td {border-top: none; color: #000000; font-weight: 500;}
#seller-panel .btn-deflt.mr-2{width: 70px;}
#seller-panel .btn-deflt {background: rgba(255, 85, 1, 0.3); color: #FF5501 !important;font-size: 14px; padding: 5px 10px;}  
#seller-panel .product-tab {border-bottom: none; padding-bottom: 0px;}
#seller-panel .product-tab .nav-link{padding: 4px 25px;}
#seller-panel .seemore p a { color: #69A227 !important; font-size: 16px; font-weight: 700;}
#seller-panel .my-Adsbox {background: #EBF2E8; border: 1px solid #FFFFFF; border-radius: 10px; min-height: 332px;}
#seller-panel .my-Adsbox .nav-tabs .nav-link.active { background: transparent;}

#seller-panel .sntrd-box { background: rgba(255, 85, 1, 0.08); }
.details-rightobx h2 img {margin-right: 20px; cursor: pointer;}
#seller-panel .bg-newimg {border-radius: 0px;height: 200px !important;width: 100%;}
#seller-panel .edit-userpic img {width: 100%;height: 100% !important;object-fit: cover;border-radius: 50%;position: relative;z-index: 9;}
#seller-panel .new-boxbroser { background: rgba(255, 85, 1, 0.15);border: 1px dashed rgba(255, 85, 1, 0.28);border-radius: 3px;padding: 30px;height: 196px;}
#seller-panel .msg-tablenew thead { background: transparent !important;  box-shadow: none !important;  border-radius: none !important; }

#seller-panel .new-chatscroll { height: 418px; overflow-x: hidden; overflow-y: scroll; }
#seller-panel .new-chatscroll::-webkit-scrollbar { display: none;}
#seller-panel .pageinnner-padding::-webkit-scrollbar { display: none }
#seller-panel .side-menu-scroll::-webkit-scrollbar { display: none }
#seller-panel .new-scrollleftchat::-webkit-scrollbar { display: none }

#seller-panel .new-tabconnect .nav-item { background: #69A22733 !important; padding: 5px  !important; }
#seller-panel .chat-table td .badge-red { background-color: #69A227;}
#seller-panel .new-tabconnect .nav-item:last-child { border-top-right-radius: 30px; border-bottom-right-radius: 30px; }
#seller-panel .new-tabconnect .nav-item :first-child { border-top-left-radius:  30px;  border-bottom-left-radius: 30px; }

#seller-panel .left-atteched { position: absolute; top: 5px; left: 5px; }
#seller-panel  .right-sendconnect { position: absolute; top: 5px; right: 5px; }

#seller-panel .new-tabconnect .nav-link.active { background: #FFFFFF !important; border-radius: 50px; font-weight: 600; font-size: 10px;  color: #FF5501; border: none; }
#seller-panel .new-tabconnect .nav-link { font-weight: 700 !important; font-size: 10px !important;  color: #747474 !important; border-radius: 50px !important; padding: 3px 10px !important;}
#seller-panel .connect-inpt { background: #FFFFFF; border: 1px solid #69A227;  border-radius: 50px; padding: 17px 40px; font-weight: 500; font-size: 12px;  color: #B8B8B8; }
#seller-panel .chat-table thead th { border-top: 0px;}

#seller-panel .middle-userpic { width: 50px; height:50px; border-radius: 50%; position: relative; margin-right: 15px; }
#seller-panel .middle-userpic img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
#seller-panel .connect-usercontent h6 {
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    margin-bottom: 0px;
}
#seller-panel .chat-boxcontent {
    background: #69A2274D;
    border-radius: 0px 20px 20px 20px;
    padding: 10px 15px;
    margin-top: 7px;
    max-width: 250px;
}
#seller-panel .connect-usercontent h6 span {
    font-weight: 300;
    font-size: 11px;
    color: #AAACAD;
}
#seller-panel .newconnect-usercontent h6 {
    text-align: right;
}
#seller-panel .newchat-boxcontent {
    border-radius: 20px 0px 20px 20px; }

#seller-panel .middle-userpic { width: 50px; height: 50px; border-radius: 50%; position: relative; margin-right: 15px; }
#seller-panel .middle-picrigth { margin-right: 0px; margin-left: 15px; 
    }


    #seller-panel  .connect-userbox {
        width: 56px;
        height: 56px ;
        border-radius: 50%;
        border: 2px solid #1CA75A;
        z-index: 2;
        margin-top:  -20px ;
        margin-left: 
    20px
    ;
        position: relative;
    }
    .connect-userbox img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    
    


// #seller-panel .chat-table thead { border-radius: 10px 10px 0px 0px;}
// ========================================================================
// .manage-table thead { border: 1px solid #DBDADE !important;}
// .manage-table thead td { border: none; }
// .manage-table tbody { border: 1px solid #DBDADE !important;}
// .manage-table thead th { color: var(--light-typography-color-heading-text, #69A227) !important; font-size: 12px; font-weight: 600; line-height: normal; letter-spacing: 1px; text-transform: uppercase;}
// .manage-table tbody td { vertical-align: middle; font-size: 14px; font-weight: 400; color: var(--light-typography-color-body-text, #4B465C);}
// .btn-edit { padding: 8px 30px; font-size: 10px; background: rgba(105, 162, 39, 0.30); border-radius: 0px; font-size: 14px; font-weight: 500; color: #69A227 !important;}    

// #seller-panel .my-Adsbox .nav-tabs 
// #seller-panel .my-Adsbox .nav-tabs .nav-link { border: ;}

.my-Adsbox .nav-tabs .nav-item { margin-right: 10px; }
.my-Adsbox .nav-tabs .nav-item:last-child { margin-right: 0px; }
.my-Adsbox .nav-tabs .nav-link { width: 105px; }
#seller-panel .my-Adsbox .nav-tabs .nav-item .nav-link.active { border: 1px solid #69a227; border-radius: 10px;}
#seller-panel .my-Adsbox .nav-tabs .nav-link.active h1 { color: #69A227;}
#seller-panel .my-Adsbox .nav-tabs .nav-item .nav-link { background-color: transparent; border-radius: 10px; border: 1px dashed #C0C0C0; text-align: center;}
#seller-panel .my-Adsbox .nav-tabs .nav-link h1 { font-size: 15px; color: #4B465C; font-weight: 600; margin: 0px;}
#seller-panel .my-Adsbox .nav-tabs .nav-link.active .my-ad-icon {display: inline-block; border-radius: 6px; background: rgba(105, 162, 39, 0.08); box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); padding: 8px 10px; margin-bottom: 10px;}
#seller-panel .my-Adsbox .nav-tabs .nav-link .my-ad-icon {border-radius: 6px; display: inline-block; background: rgba(75, 70, 92, 0.08); padding: 8px 10px; margin-bottom: 10px;}
#seller-panel .my-Adsbox .nav-tabs { justify-content: center;}
.standard-yearly-head h1 { color: #69A227; font-size: 20px; font-weight: 800;}
.standard-yearly-head {justify-content: space-between; align-items: center; display: flex;}
// .stndrd-yrly thead th 
.stndrd-yrly thead th { color: #69A227 !important; border: 1px solid #CCCCCC !important; padding: 8px 10px;}
.stndrd-yrly tbody td { color: #4E4E4E !important; font-weight: 600; border: 1px solid #CCCCCC !important; padding: 8px 10px;}
.buy-table tr td:first-child { color: #969696 !important; font-weight: 500;}
.stndrd-yrly { background: #ffffff !important;}

.table-modal-pop .modal-dialog{ background: #ffffff;}
.switch { position: relative;  display: inline-block;  width: 30px; height: 18px; margin-bottom: 0px;}
.switch input {  opacity: 0;  width: 0; height: 0; }
.slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ffffff ; 
-webkit-transition: .4s;  transition: .4s; border: 1px solid #000000;}
.slider:before { position: absolute; content: ""; height: 12px; width: 12px; left: 3px; bottom: 2px; background-color: #686868; 
-webkit-transition: .4s;  transition: .4s;}
input:checked + .slider { background-color: #69A227; border: 1px solid #69A227;}
input:focus + .slider { box-shadow: 0 0 1px #6B6B6B;}
input:checked + .slider:before { -webkit-transform: translateX(26px); -ms-transform: translateX(26px);  transform: translateX(26px);  left: -11px; background-color: #ffffff;}

#seller-panel .premium-member-area {background: rgba(105, 162, 39, 0.30); padding: 5px;}
#seller-panel .premium-member-area p { margin: 0px; color: #69A227; font-size: 13px; font-weight: 600;}
.details-user ul { padding: 0px 15px;}
.details-user ul li { line-height: 35px; color: #4B465C; font-size: 15px; font-weight: 600;}
.details-user ul li span { padding-left: 10px;}
.details-user ul li .dets-span { color: #7E7977; font-weight: 400;}
#seller-panel .btn-listing { padding: 3px 6px !important; border-radius: 5px !important;}
.location-icon img { height: 20px;}
.location-icon p { margin: 0px; color: #7E7977; font-size: 13px; font-weight: 500;}

.user-deatilsbox { background: #FFFFFF; border-radius: 0px; box-shadow: 0px 4px 18px 0px rgba(75, 70, 92, 0.10);}
.user-deatilsbg {min-height: 140px;}
.userdetails-pic { margin: 0 auto; width: 120px; margin-top: -80px; }
.userdetails-pic img { width: 100%; height: 100%; object-fit: cover; border-radius: 0px; }
.user-deatilsbox h1 { color: #7E7977; font-weight: 700; font-size: 28px; text-align: center; margin-bottom: 0px; padding-bottom: 12px;}
.padding-userdetails { padding: 15px; }
.social-text { color: #7E7977; font-weight: 400; font-size: 16px; align-items: center;  }
.deatils-box { padding-left: 20px; padding-top: 15px; }
.social-icon { width: 24px; margin-right: 10px; }

.details-rightobx {background: #F9F9F9; border-radius: 0px; padding: 5px 15px; }
.details-rightobx h1 {  color: #7E7977; font-weight: 500; font-size: 18px; margin-bottom: 0px; padding-top: 10px; display: flex; align-items: center; }
.details-rightobx h1 img { margin-right: 10px; }
.details-rightobx p { color: #B0B0B0; font-weight: 400; font-size: 13px; margin-bottom: 0px; }
.details-rightobx h6 { color: #2B2A2A; font-weight: 400; margin-bottom: 0px; font-size: 12px; }
.my-subscriptionbox { background: #F5E7FF; border-radius: 0px; padding: 25px; margin-top: 0px; }

#seller-panel .newpremium-btn {background: linear-gradient(95.68deg, #D386FF 25.21%, #A25CFE 75.5%); border-radius: 0px; font-weight: 700; font-size: 12px;
color: #FFFFFF !important; padding: 2px 8px;}
.my-subscriptionbox h1 { font-weight: 600; font-size: 18px; color: #69A227;  margin-bottom: 0px;}
.my-subscriptionbox p { font-weight: 600; font-size: 12px; color: #2B2A2A; margin-bottom: 0px; }
.my-subscriptionbox h6 { font-weight: 600; font-size: 12px; color: #606060; margin-bottom: 0px; }
.subscription-btn { background: #69A227; border-radius: 0px; padding: 3px 25px; font-weight: 600; font-size: 12px; color: #FFFFFF; }
.subscription-btn:hover { color: #FFFFFF; }
.deatilslogo-box {height: 170px; width: 100%;}
// .deatilslogo-box { height: 100%; width: 100%;}
.deatilslogo-box img {  width: 100%; height: 100%; object-fit: contain;}
#seller-panel .subscription-btn { color: #FFF; font-size: 14px; font-weight: 500; background: #69A227; border-radius: 0px;}
#seller-panel .btn:hover { box-shadow: none !important; outline: none !important;}
#seller-panel .category-gap-radio [type="radio"]:not(:checked) + label, [type="radio"]:checked + label { color: #7E7977 !important; font-size: 14px !important; font-weight: 400 !important; }
#seller-panel .btn-filter {background: rgba(105, 162, 39, 0.15); border-radius: 0px;}
#seller-panel .btn-filternew {background: rgba(105, 162, 39, 0.15); border-radius: 0px; border: none; box-shadow: none; outline: none; }

.drop-filternew .dropdown-toggle::after { display: none; }

#seller-panel .drop-filternew .dropdown-item img { margin-right: 9px;}
#seller-panel .drop-filternew .dropdown-item { border-bottom: 0.5px solid #C6C6C6; font-weight: 400; font-size: 14px; color: #AAAAAA; padding: 0.8rem 0.7rem; }
#seller-panel .drop-filternew .dropdown-item:last-child { border-bottom: none; }
#seller-panel .drop-filternew .dropdown-menu { padding: 0px 0px; border: none; margin-top: 17px; }

#seller-panel .product-tab .nav-item{ background-color: rgba(105, 162, 39, 0.15); padding: 5px;}
// #seller-panel .user-tabs .nav-item .nav-link.active {}
#seller-panel .new-generate {background: #EAEAEA; color: #888888 !important;}
#seller-panel .posting-table thead th { border-top: 1px solid #DBDADE; border-bottom: 1px solid #DBDADE !important;}
#seller-panel .posting-table thead th:first-child { border-left: 1px solid #DBDADE;}
#seller-panel .posting-table thead th:last-child { border-right: 1px solid #DBDADE;}
#seller-panel .posting-table tbody td:first-child { border-left: 1px solid #DBDADE;}
#seller-panel .posting-table tbody td:last-child { border-right: 1px solid #DBDADE}
#seller-panel .btn-edit { padding: 10px 15px; font-size: 13px;  background-color: rgba(105, 162, 39, 0.3); border-radius: 0px; font-weight: 500; color: #69A227 !important;} 
#seller-panel .btn-edit img { height: 20px;}
// #seller-panel .btn-edit img { height: 40px !important;}
.static-area { background: transparent; border: 1px solid #DBDADE; border-radius: 5px;}
.static-head h1 { color: var(--light-typography-color-heading-text, #4B465C); font-size: 18px; font-weight: 600; margin-bottom: 0px;}
.static-select select {background: rgba(105, 162, 39, 0.15) !important; color: #69A227 !important; font-size: 14px; font-weight: 500; border-radius: 0px; border: 0px;}
.posting-text h1 { margin: 0px; color: var(--light-typography-color-heading-text, #4B465C); font-size: 18px; font-weight: 600;}
.posting-text p { margin: 0px; color: var(--light-typography-color-body-text, #4B465C);
    font-size: 11px; font-weight: 600;}
.postig-icon { margin-right: 5px;}
.postig-icon img { border-radius: 50px; height: 40px;}
.static-area { padding: 15px !important;}
.pass-group-bg { background-color: #ffffff; padding: 3px 6px; border-radius: 50px;}
.pass-group-bg img { height: 18px; width: 18px;}
#seller-panel .input-box { background: transparent;border-radius: 5px;
    border: 1px solid rgba(126, 121, 119, 0.50); color: #8A8A8A !important;}
#seller-panel .input-box::placeholder {color:#8A8A8A;}

#seller-panel .ekta-hover { cursor: pointer; position: relative; display: inline-block;}
#seller-panel .ekta-hover .tooltip-text { visibility: hidden; width: 400px; position: absolute; z-index: 100; right: 30px; color: #000000; overflow: hidden; font-size: 14px; font-weight: 400; }


#seller-panel .new-postwrapper input[type=file] {
    left: 0px;
    height: 45px;
    width: 140px;
}

.noflexwrap { flex-wrap: inherit !important; overflow-x: scroll; }

.noflexwrap::-webkit-scrollbar { width: 4px; height: 6px; display: block; }
.noflexwrap::-webkit-scrollbar-track { box-shadow: none; background: #ECECEC; border-radius: 10px; }
.noflexwrap::-webkit-scrollbar-thumb { background-color: #69A227; outline: none; border-radius: 10px; }

.payment-links-icon img {margin-right: 5px; height: 20px;}

.service-head-text h1 { font-size: 16px; color: #69A227; font-weight: 600; margin: 0px;}

.dlt-model .modal-content { background: #ffffff;}
.dlt-sec h1 { font-size: 20px; font-weight: 700; color: #69A227;}
.delete-boxgif img { height: 155px;}
// .delete-boxgif img { height: 100%; width: 100%; object-fit: contain;}
.dlt-sec-btn .sparessborder-btn {     border: 1px solid #69A227;
    border-radius: 0px;
    font-weight: 600;
    font-size: 14px;
    color: #69A227 !important;
    padding: 8px 40px;
}
.dlt-sec-btn .sparessfill-btn { 
    background: #69A227;
    border-radius: 0px;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF !important;
    padding: 8px 40px;
}
.modal-genrate .modal-content { background: #ffffff;}
.modal-genrate .modal-body { padding: 20px 30px;}
.modal-genrate .modal-body form small p { margin: 0px; color: #5C4F4F; font-size: 12px; font-weight: 600;}

#seller-panel .back-arrow-btn { border: 1px solid #69A227; border-radius: 0px; padding: 8px 12px;}
// .promt-headtext { display: flex; justify-content: space-between; align-items: center;}
.promt-headtext h1 { font-size: 20px; color: #ffffff; font-weight: 700; margin: 0px;}
.promt-headtext p { font-size: 13px; color: #FF5501; font-weight: 800; margin: 0px;border-radius: 60px; background: rgba(255, 255, 255, 0.50); padding: 6px 12px;}
.promt-headtext p span { padding: 0px 5px;}
.promotion-card {padding: 20px; border-radius: 8px; background-size: cover !important; min-height: 220px; background-position: bottom right !important;}
.promt-content-text p {margin: 0px; color: #ffffff; font-size: 20px; font-weight: 400;}
.promotion-card-1{ background: url(../images/promt-card-1.png) no-repeat;}
.promotion-card-2{ background: url(../images/promt-card-2.png) no-repeat;}
.promotion-card-3{ background: url(../images/promt-card-3.png) no-repeat;}

.green-uploadimg { width: 100%; height: 90px; }
.green-uploadimg img { width: 100%; height: 100%; object-fit: cover; border-radius: 10px; }
.noflexwrapgreen { flex-wrap: inherit; overflow-x: scroll; }
.noflexwrapgreen::-webkit-scrollbar { width: 4px; display: block;  }
.wishlist-heartimg img { height: 50px; }

.top-tex h6 { padding-top: 15px; color: #fff; }
.top-tex p { padding-top: 15px; color: #fff !important; }



