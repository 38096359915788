[type="radio"]:not(:checked), [type="radio"]:checked {
	position:absolute;
	left:-9999px;
	opacity:0
	}
	[type="radio"]:not(:checked)+label, [type="radio"]:checked+label {
	position: relative;
		padding-left: 27px;
		cursor: pointer;
		display: inline-block;
		height: 25px;
		line-height: 25px;
		font-size: 13px;
		transition: .28s ease;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		margin-right: 15px;
	}
	[type="radio"]+label:before, [type="radio"]+label:after {
	content:'';
	position:absolute;
	left:0;
	top:0;
	margin:4px;
	width:16px;
	height:16px;
	z-index:0;
	transition:.28s ease
	}
	[type="radio"]:not(:checked)+label:before, [type="radio"]:not(:checked)+label:after, [type="radio"]:checked+label:before, [type="radio"]:checked+label:after, [type="radio"].with-gap:checked+label:before, [type="radio"].with-gap:checked+label:after {
	border-radius:50%
	}
	[type="radio"]:not(:checked)+label:before, [type="radio"]:not(:checked)+label:after {
	border:2px solid #5a5a5a
	}
	[type="radio"]:not(:checked)+label:after {
	-webkit-transform:scale(0);
	transform:scale(0)
	}
	[type="radio"]:checked+label:before {
		border:2px solid transparent
	}
	[type="radio"]:checked+label:after, [type="radio"].with-gap:checked+label:before, [type="radio"].with-gap:checked+label:after {
		border:2px solid #51459E
	}
	[type="radio"]:checked+label:after, [type="radio"].with-gap:checked+label:after {
		background-color: #51459E
	}
	[type="radio"]:checked+label:after {
		-webkit-transform:scale(1.02);
		transform:scale(1.02)
	}
	[type="radio"].with-gap:checked+label:after {
		-webkit-transform:scale(0.5);
		transform:scale(0.5)
	}
	[type="radio"].tabbed:focus+label:before {
		box-shadow:0 0 0 10px rgba(0, 0, 0, 0.1)
	}
	[type="radio"].with-gap:disabled:checked+label:before {
		border:2px solid rgba(0, 0, 0, 0.26)
	}
	[type="radio"].with-gap:disabled:checked+label:after {
		border:none;
		background-color:rgba(0, 0, 0, 0.26)
	}
	[type="radio"]:disabled:not(:checked)+label:before, [type="radio"]:disabled:checked+label:before {
	background-color:transparent;
	border-color:rgba(0, 0, 0, 0.26)
	}
	[type="radio"]:disabled+label {
		color:rgba(0, 0, 0, 0.26)
	}
	[type="radio"]:disabled:not(:checked)+label:before {
	border-color:rgba(0, 0, 0, 0.26)
	}
	[type="radio"]:disabled:checked+label:after {
		background-color:rgba(0, 0, 0, 0.26);
		border-color:#BDBDBD
	}
	
	
	.input-field.col .dropdown-content [type="checkbox"]+label {
		top:1px;
		left:0;
		height:18px
	} 
	[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
	position:absolute;
	left:-9999px;
	opacity:0
	}
	[type="checkbox"]+label {
	position:relative;
	padding-left:28px;
	cursor:pointer;
	display:inline-block;
	height:15px;
	line-height:19px;
	font-size:0.8rem;
	font-weight:normal;
	-webkit-user-select:none;
	-moz-user-select:none;
	-khtml-user-select:none;
	-ms-user-select:none;
	color:#51C6ED;
	font-weight:500 !important;
	margin-bottom:0
	}
	[type="checkbox"]+label:before, [type="checkbox"]:not(.filled-in)+label:after {
	content:'';
	position:absolute;
	top:0;
	left:0;
	width:18px;
	height:18px;
	z-index:0;
	border:2px solid #5a5a5a;
	border-radius:1px;
	margin-top:2px;
	transition:.2s
	}
	[type="checkbox"]:not(.filled-in)+label:after {
	border:0;
	-webkit-transform:scale(0);
	transform:scale(0)
	}
	[type="checkbox"]:not(:checked):disabled+label:before {
	border:none;
	background-color:rgba(0, 0, 0, 0.26)
	}
	[type="checkbox"].tabbed:focus+label:after {
		-webkit-transform:scale(1);
		transform:scale(1);
		border:0;
		border-radius:50%;
		box-shadow:0 0 0 10px rgba(0, 0, 0, 0.1);
		background-color:rgba(0, 0, 0, 0.1)
	}
	[type="checkbox"]:checked+label:before {
		top:-4px;
		left:-5px;
		width:12px;
		height:22px;
		border-top:2px solid transparent;
		border-left:2px solid transparent;
		border-right:2px solid #51C6ED;
		border-bottom:2px solid #51C6ED;
		-webkit-transform:rotate(40deg);
		transform:rotate(40deg);
		-webkit-backface-visibility:hidden;
		backface-visibility:hidden;
		-webkit-transform-origin:100% 100%;
		transform-origin:100% 100%
	}
	[type="checkbox"]:checked:disabled+label:before {
		border-right:2px solid rgba(0, 0, 0, 0.26);
		border-bottom:2px solid rgba(0, 0, 0, 0.26)
	}
	[type="checkbox"]:indeterminate+label:before {
		top:-11px;
		left:-12px;
		width:10px;
		height:22px;
		border-top:none;
		border-left:none;
		border-right:2px solid #51C6ED;
		border-bottom:none;
		-webkit-transform:rotate(90deg);
		transform:rotate(90deg);
		-webkit-backface-visibility:hidden;
		backface-visibility:hidden;
		-webkit-transform-origin:100% 100%;
		transform-origin:100% 100%
	}
	[type="checkbox"]:indeterminate:disabled+label:before {
		border-right:2px solid rgba(0, 0, 0, 0.26);
		background-color:transparent
	}
	[type="checkbox"].filled-in+label:after {
		border-radius:2px
	}
	[type="checkbox"].filled-in+label:before, [type="checkbox"].filled-in+label:after {
		content:'';
		left:0;
		position:absolute;
	transition:border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
		z-index:1
	}
	[type="checkbox"].filled-in:not(:checked)+label:before {
	width:0;
	height:0;
	border:3px solid transparent;
	left:6px;
	top:10px;
	-webkit-transform:rotateZ(37deg);
	transform:rotateZ(37deg);
	-webkit-transform-origin:20% 40%;
	transform-origin:100% 100%
	}
	[type="checkbox"].filled-in:not(:checked)+label:after {
	height:20px;
	width:20px;
	background-color:transparent;
	border:2px solid #2424244D;
	top:0px;
	z-index:0;
	border-radius: 5px;
	}
	[type="checkbox"].filled-in:checked+label:before {
		top: 3px;
    left: 3px;
    width: 6px;
    height: 10px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #51459e;
    border-bottom: 2px solid #51459e;
    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
	}
	[type="checkbox"].filled-in:checked+label:after {
		top:0;
		width:20px;
		height:20px;
		border:2px solid #51459E;
		background-color: #51459E15;
		z-index:0;
			border-radius: 5px;
	}
	[type="checkbox"].filled-in.tabbed:focus+label:after {
		border-radius:2px;
		border-color:#5a5a5a;
		background-color:rgba(0, 0, 0, 0.1)
	}
	[type="checkbox"].filled-in.tabbed:checked:focus+label:after {
		border-radius:2px;
		background-color:#51C6ED;
		border-color:#51C6ED
	}
	[type="checkbox"].filled-in:disabled:not(:checked)+label:before {
	background-color:transparent;
	border:2px solid transparent
	}
	[type="checkbox"].filled-in:disabled:not(:checked)+label:after {
	border-color:transparent;
	background-color:#BDBDBD
	}
	[type="checkbox"].filled-in:disabled:checked+label:before {
		background-color:transparent
	}
	[type="checkbox"].filled-in:disabled:checked+label:after {
		background-color:#BDBDBD;
		border-color:#BDBDBD
	}
	  
	input[type=checkbox]:checked:not(:disabled) ~ .lever:active::after, input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::after {
	box-shadow:0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1)
	}
	input[type=checkbox]:not(:disabled) ~ .lever:active:after, input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::after {
	box-shadow:0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08)
	}

	.desgin-radio [type="radio"]:not(:checked) + label:before, .desgin-radio [type="radio"]:not(:checked) + label:after {
		// border: 2px solid #5a5a5a;
		border: 2px solid #2424244D;
	}
	.desgin-radio [type="radio"] + label:before, .desgin-radio [type="radio"] + label:after {
		height: 22px;
		width: 22px;
	}

	// radio-btn
	
	.timeinput [type="radio"]:not(:checked)+label, .timeinput [type="radio"]:checked+label {
		padding-left: 0;
		line-height: 32px;
		height: 32px;
		margin-bottom: 10px !important;
		text-align: center;
		padding-left: 25px;
		padding-right: 25px;
		font-size: 12px;
		font-weight: 500;
		color: #242424;
		width: 124px;
	}
	.timeinput [type="radio"]:not(:checked)+label:before, .timeinput [type="radio"]:not(:checked)+label:after {
		border: 1px solid #51459E33;
		background: #51459E0D;
		border-radius: 5px;
		margin: 0;
		z-index: -1;
	}
	.timeinput [type="radio"]:not(:checked)+label:before, .timeinput [type="radio"]:not(:checked)+label:after {
		border: 1px solid #51459E33;
		background: #51459E0D;
		border-radius: 5px;
		margin: 0;
		z-index: -1;
	}

	.timeinput [type="radio"]+label:before, .timeinput [type="radio"]+label:after {
		width: 100%;
		// border-radius: 0px;
		height: 32px;
	}

	// 
	.timeinput [type="radio"]:checked+label {
		color: #fff;
		background: #51459E;
		border-radius: 5px;
		font-weight: 500;
	}
	.timeinput [type="radio"]:not(:checked)+label, .timeinput [type="radio"]:checked+label {
		// padding-left: 0;
		line-height: 32px;
		height: 32px;
		margin-bottom: 0;
		text-align: center;
		padding-left: 0px;
		padding-right: 0px;
		font-size: 12px;
		// font-weight: 500;
		width:124px;
	}
	
	.timeinput [type="radio"]:checked+label:after, .timeinput [type="radio"].with-gap:checked+label:before, .timeinput [type="radio"].with-gap:checked+label:after {
		border: 1px solid #51459E;
		border-radius: 5px;
		margin: 0;
	}
	.timeinput [type="radio"]+label:before, .timeinput [type="radio"]+label:after {
		width: 100%;
		height: 32px;
	}
	
	.timeinput [type="radio"]:checked+label:after, .timeinput [type="radio"].with-gap:checked+label:before, .timeinput [type="radio"].with-gap:checked+label:after {
		border-radius: 5px;
		margin: 0;
	}
	.timeinput [type="radio"]:checked+label:after, .timeinput [type="radio"].with-gap:checked+label:after {
		background: none;
		border: none !important;
	}



// new-time slot

.accountTime-slot [type="radio"]:not(:checked)+label, .accountTime-slot [type="radio"]:checked+label {
	padding-left: 0;
	line-height: 32px;
	height: 32px;
	margin-bottom: 10px !important;
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 12px;
	font-weight: 500;
	color: #242424;
	width: 110px;
}
.accountTime-slot [type="radio"]:not(:checked)+label:before, .accountTime-slot [type="radio"]:not(:checked)+label:after {
	border: 1px solid #51459E33;
	// background: #51459E0D;
	border-radius: 5px;
	margin: 0;
	z-index: -1;
}
.accountTime-slot [type="radio"]:not(:checked)+label:before, .accountTime-slot [type="radio"]:not(:checked)+label:after {
	border: 1px solid #51459E33;
	// background: #51459E0D;
	border-radius: 5px;
	margin: 0;
	z-index: -1;
}

.accountTime-slot [type="radio"]+label:before, .accountTime-slot [type="radio"]+label:after {
	width: 100%;
	// border-radius: 0px;
	height: 32px;
}

// 
.accountTime-slot [type="radio"]:checked+label {
	color: #51459E;
	background: #51459E0D;
	border-radius: 5px;
	font-weight: 500;
}
.accountTime-slot [type="radio"]:not(:checked)+label, .accountTime-slot [type="radio"]:checked+label {
	// padding-left: 0;
	line-height: 32px;
	height: 32px;
	margin-bottom: 0;
	text-align: center;
	padding-left: 0px;
	padding-right: 0px;
	font-size: 12px;
	// font-weight: 500;
	width:110px;
}

.accountTime-slot [type="radio"]:checked+label:after, .accountTime-slot [type="radio"].with-gap:checked+label:before, .accountTime-slot [type="radio"].with-gap:checked+label:after {
	border: 1px solid #51459E;
	border-radius: 5px;
	margin: 0;
}
.accountTime-slot [type="radio"]+label:before, .accountTime-slot [type="radio"]+label:after {
	width: 100%;
	height: 32px;
}

.accountTime-slot [type="radio"]:checked+label:after, .accountTime-slot [type="radio"].with-gap:checked+label:before, .accountTime-slot [type="radio"].with-gap:checked+label:after {
	border-radius: 5px;
	margin: 0;
}
.accountTime-slot [type="radio"]:checked+label:after, .accountTime-slot [type="radio"].with-gap:checked+label:after {
	background: none;
	border: none !important;
}

 
.register-radio [type="radio"].with-gap:checked + label:before, .register-radio [type="radio"].with-gap:checked + label:after {
	 border: 2px solid #FFFFFF; background: #FF5501; }

// .register-radio [type="radio"]:checked + label:after, .register-radio [type="radio"].with-gap:checked + label:after {
//     background-color: #B7410E; }

// .register-radio	[type="radio"]:not(:checked) + label:after { border: 2px solid #D9D9D9; }

.register-radio [type="radio"]:not(:checked) + label:before, .register-radio [type="radio"]:not(:checked) + label:after {
    border: 2px solid #D9D9D9; background: #D9D9D9;}

// ==========================
.new-checkfilled [type="checkbox"].filled-in:not(:checked) + label:after { background: #D9D9D9; border: 1px solid #FF5501; border-radius: 3px; }
.new-checkfilled [type="checkbox"].filled-in:checked + label:after {  background: #D9D9D9;
	border: 1px solid #FF5501;
	border-radius: 3px; }
.new-checkfilled [type="checkbox"].filled-in:checked + label:before { border-right: 2px solid #FF5501; border-bottom: 2px solid #FF5501; }

//deal =======================
.deal-radio [type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:after {
    background-color: #FF5501;
}

.deal-radio [type="radio"]:checked + label:after, .deal-radio [type="radio"].with-gap:checked + label:before, .deal-radio [type="radio"].with-gap:checked + label:after { border: 2px solid #FF5501; }
.deal-radio [type="radio"]:not(:checked) + label:before, .deal-radio [type="radio"]:not(:checked) + label:after { border: 2px solid #FF5501;
}


// ===========================
.experiance-check [type="checkbox"].filled-in:checked + label:before {
    top: 3px;
    left: 3px;
    width: 6px;
    height: 10px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #FF5501;
    border-bottom: 2px solid #FF5501;
    transform: rotateZ(37deg);
    transform-origin: 100% 100%;
}

.experiance-check [type="checkbox"].filled-in:checked + label:after {
    border: 2px solid #D6D6D6;
    z-index: 0;
    border-radius: 3px;
}

.experiance-check [type="checkbox"].filled-in:not(:checked) + label:after {
    border: 2px solid #D6D6D6;
    border-radius: 3px;
}
.exp-label { font-weight: 300 !important; font-size: 12px !important; color: #000000 !important;}

// =======================================================================
// .category-gap-radio [type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:before, [type="radio"].with-gap:checked + label:after{}
.category-gap-radio [type="radio"]:not(:checked) + label:before, [type="radio"]:not(:checked) + label:after, [type="radio"]:checked + label:before, [type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:before, [type="radio"].with-gap:checked + label:after	 {  background: #69A227;}
.category-gap-radio [type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:after { background: #FFFFFF !important;}
.category-gap-radio [type="radio"]:checked + label:after, [type="radio"].with-gap:checked + label:before, [type="radio"].with-gap:checked + label:after { border: 2px solid #69A227 !important;}
.category-gap-radio [type="radio"]:not(:checked) + label:before { background: none !important; border: 2px solid #69A227}