.video-call-container {
    /* max-width: 1134px !important; */
    height: 615px !important;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 3px 6px #2C28281C;
    margin: 10px 0;
    position: relative;
}

.video-call-container .local-container {
    width: 202px;
    height: 248px;
    background: transparent 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2C28281C;
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    border: 2px solid var(--cl-green)
}

.video-call-container .local-container div {
    width: 100%;
    height: 100%;
    position: initial !important;
    border-radius: 10px;
}

.video-call-container .local-container div video {
    width: 100%;
    height: 100%;
    position: initial !important;
    border-radius: 10px;
}

/* Remote video layout */
.video-call-container .remote-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
}

.video-call-container .remote-container .remote-video {
    height: 100%;
    width: 80%;
    border-radius: 10px;
}

.video-call-container .remote-container .remote-video div {
    width: 100% !important;
    height: 80% !important;
    position: initial !important;
    border-radius: 10px;
}

.video-call-container .remote-container .remote-video div video {
    width: 100% !important;
    height: 80% !important;
    position: initial !important;
    border-radius: 10px;
}

.video-call-container .remote-container .remote-video2 {
    width: 202px;
    height: 248px;
    position: absolute;
    right: 20px;
    bottom: 80px;
}

.video-call-container .remote-container .remote-video2 div {
    width: 100% !important;
    height: 100% !important;
    position: initial !important;
    border-radius: 10px;
}

.video-call-container .remote-container .remote-video2 div video {
    width: 100% !important;
    height: 100% !important;
    position: initial !important;
    border-radius: 10px;
}

.video-call-container #guest-user {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: cyan;
    color: white;
}

.video-call-container #guest-user {
    font-size: 30px;
}

/* Footer layout */
.video-call-container .video-footer {
    position: absolute;
    bottom: 20px;
    /* top: 0px; */
    height: 70px;
    width: 100%;
    right: 0px;
    left: 0px;
}

.video-call-container .video-footer .call-icon-box .contact-uservideo {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.video-call-container .video-footer .call-icon-box .circle1 {
    width: 60px;
    height: 60px;
    background-color: #B8C5D3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 10px 0;
    cursor: pointer;
}

.video-call-container .video-footer .call-icon-box .circle2 {
    width: 60px;
    height: 60px;
    background-color: #2DCE98;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 10px 0;
    cursor: pointer;
}

.end-call-btn {
    border-radius: 50%;
    background-color: rgba(255, 51, 0, 0.586);
    width: 60px;
    height: 58px;

}





.padding-videocontent { height: 568px; padding: 25px 25px 25px 25px; }
.video-calluser { width: 100%;  height: 520px; }
.video-calluser .user-bgimg {  width: 100%; height: 70%; object-fit: cover; border-radius: 15px; }
.user-imgvideocallmainbox { position: absolute; top: 210px; right: 20px; width: 185px; height: 230px; }
.user-imgvideocallmainbox img { width: 100%; height: 70%; object-fit: cover; border-radius: 15px; }

.mute-box { width: 40px; height: 40px; background: transparent; border-radius: 50%; text-align: center; line-height: 40px; }
.video-main { width: 40px; height: 40px; background: transparent; border-radius: 50%; text-align: center; line-height: 40px; }

.contact-uservideo { position: absolute; display: flex; bottom: 50px;  justify-content: center; text-align: center; left: 0; right: 0; margin: 0 auto; margin-bottom: -18px }

.user-videocall { font-weight: 700; font-size: 14px;  color: #FFFFFF;  margin-bottom: 0px; z-index: 2;
position: absolute; bottom: 10px; text-align: center;  left: 40%; }
.audio-recording { background: rgba(0, 0, 0, 0.4); border-radius: 40px; width: 103px; position: absolute; display: flex;
align-items: center; top: 156px;  right: 65px; padding: 5px; }
.white-box { width: 23px; height: 23px; background: #FFFFFF; border-radius: 50%; text-align: center; line-height: 20px; margin-right: 10px; }

.audio-recording p { font-weight: 600; font-size: 16px; color: #FFFFFF; margin-bottom: 0px; }
.video-contenttext { background: #99D15780; border-radius: 10px; padding: 10px 15px }

.video-contenttext p { font-weight: 300; font-size: 14px; color: #000000; margin-bottom: 0px; }
.video-contenttext p span { color: #FF5501; }

.newborder-bottom { border-bottom: 1px solid #FF5501; }

.video-chatbox { background: #FF5501; border-radius: 0px 20px 20px 20px; padding: 4px 12px; }
.video-chatbox p { font-weight: 400; font-size: 12px; color: #FFFFFF; margin-bottom: 0px; }
.time-videochat { font-weight: 500; font-size: 10px; color: #6B6B6B;}

.cliect-videochat { background: #FFFFFF; border-radius: 20px 0px 20px 20px; }
.cliect-videochat p { color: #000000; }
.video-chatbtn { background: rgba(255, 85, 1, 0.35); border-radius: 2px; font-weight: 500; font-size: 10px; color: #000000; padding: 3px 10px; }

.video-box { border-radius: 20px; }

.user-imgvideocallmainbox { border-radius: 20px; }
 
