@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Goldman:wght@400;700&display=swap');

html { font-family: 'Lato', sans-serif; -webkit-font-smoothing: antialiased; }
body { font-family: 'Lato', sans-serif; -webkit-font-smoothing: antialiased; background:#fff ;}

.goldman { font-family: 'Goldman', cursive; }
.manrope { font-family: 'Manrope', sans-serif; }

.btn:hover { box-shadow: 0 6px 18px -4px rgba(0,0,0,0.5) !important; }

img { max-width: 100%;}
.fw-medium { font-weight: 500}
.fw-semi { font-weight: 600}
.header-logoimg { margin-top: -8px; }

a:hover { text-decoration: none; }

header { position: sticky; left: 0; right: 0;  top: 0; z-index: 1023; background: rgba(241, 241, 241, 1);  border-bottom: 1px solid rgba(217, 217, 217, 1); }

.tophead {  padding-bottom: 0.7rem; padding-top: 0.7rem; }
.tophead ul { padding: 0; list-style: none; margin: 0; }
.tophead ul li { display: inline-block; font-size: 13px;  padding-right: 55px; font-weight: 600; cursor: pointer; }
.tophead ul li a { color: rgba(72, 72, 72, 1); text-decoration: none; }
.tophead .tophead-right li { margin-right: 0px;}
.tophead .tophead-right li:last-child {padding-right: 0px;}
// .tophead .menu-btn-left { filter: invert(1);  }

.login-btn { border-radius: 0px; border: 1px solid rgba(105, 162, 39, 1); color: rgba(105, 162, 39, 1); font-weight: 600; font-size: 14px; padding: 4px 20px; }
.login-btn:hover { color: rgba(105, 162, 39, 1); }

// .hero-section { background: url(../images/sunset-sky.png) right 70px; background-repeat: no-repeat; }

.hero-rightimg{ height: 388px; width: 100%; }
.hero-right { text-align: center; position: relative; margin-top: -40px; }
.play-img { position: absolute; left: 0; right: 0; margin: 0 auto;}

.padding-right { padding-right: 150px !important; }
.bannerhead-textcontent h1 { color: rgba(24, 24, 24, 1); font-weight: 400; font-size: 50px; margin-bottom: 0px; }
.bannerhead-textcontent h1 span { color: rgba(105, 162, 39, 1); font-weight: 700; }
.banner-content h6 { color: rgba(0, 0, 0, 1); font-weight: 400; font-size: 16.5px; margin-bottom: 50px; line-height: 22px; }
.serch-partbox { background: rgba(105, 162, 39, 0.1); border-radius: 5px; padding: 20px 30px; }
.search-label { color: rgba(0, 0, 0, 1); font-weight: 400; font-size: 17px; }
.search-input { background: rgba(255, 255, 255, 1); border: 0.5px solid rgba(105, 162, 39, 1);  padding: 35px 18px; font-weight: 400; font-size: 15px; }
.search-input:focus { box-shadow: none; outline: none;  border: 0.5px solid rgba(105, 162, 39, 1); }
.search-input::placeholder { color: rgba(182, 182, 182, 1);  }
.search-btn { background: rgba(105, 162, 39, 1); border-radius: 5px; color: rgba(255, 255, 255, 1); font-weight: 600;
font-size: 15px; padding: 11px 32px;  }
.search-btn:hover { color: rgba(255, 255, 255, 1); }
.btn-position { position: absolute; right: 15px; top: 46px; }

.banner-content h4 { color: rgba(105, 162, 39, 1); font-weight: 800; font-size: 35px; margin-bottom: 0px; }
.banner-content p { color: rgba(33, 37, 41, 1); font-weight: 400; font-size: 15px; margin-bottom: 0px; line-height: 20px; }
.sea-section { background: linear-gradient(90deg, rgba(105, 162, 39, 1) 39%, rgba(244, 244, 244, 1) 30%); }
.land-box { background: #fff; padding: 28px 45px; }
.sea-btn { border: 2px solid #69A227; font-weight: 700; font-size: 16px; color: #69A227; padding: 6px 38px; border-radius: 0px; }
.sea-btn:hover { color: #69A227; }
.btn-quantity { border: 2px solid #69A227; font-weight: 700; font-size: 16px; color: #69A227; padding: 6px 38px; border-radius: 0px; }


.land-box h4 { color: #000000; font-weight: 600; font-size: 28px; margin-bottom: 0px; }
.land-box p { color: #000000; font-weight: 400; font-size: 16px; margin-bottom: 0px;  display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical; padding-top: 18px; line-height: 25px;}
.sectop-headcontent h3 { color: #000000; font-weight: 400; font-size: 36px; margin-bottom: 0px; }
.sectop-headcontent p { font-weight: 400; color: #0C0A0A; font-size: 16px; margin-bottom: 10px; }

.advertise-img img { height: 300px; }

.customer-text h2 { font-weight: 800; font-size: 20px; margin-bottom: 0px; color: #69A227; }


.app-section { padding: 10px 0px; background-image: -webkit-linear-gradient(150deg, #fff 50%, rgba(105, 162, 39, 0.50) 35%); }
.tree-imgbox { position: absolute; bottom: -10px; right: -45px; }
.app-qrsec h4 { color: rgba(105, 162, 39, 1); font-weight: 400; font-size: 20px; margin-bottom: 0px; }
.app-qrsec h6 { color: #69A227; font-size: 40px; font-weight: 400; margin-bottom: 0px; }
.app-qrsec { border: 1px solid rgba(185, 185, 185, 1); background: rgba(255, 255, 255, 1); padding: 10px 10px; position: relative; }

.app-qrsec:after, .app-qrsec:before { right: 100%; top: 25%; border: solid transparent; content: ""; height: 0; width: 0; position: absolute; pointer-events: none; }
.app-qrsec:after { border-color: transparent; border-right-color: rgba(255, 255, 255, 1); border-width: 130.5px 85px 0px 0px; margin-top: -32.5px; }
.app-qrsec:before { border-color: transparent; border-right-color: rgba(185, 185, 185, 1); border-width: 132.5px 86.5px 0px 0px; margin-top: -33.7px; }
.qr-boximg img { height: 110px; }


.foot-section { background: rgba(53, 53, 53, 1); }
.copy-righttext { color: rgba(255, 255, 255, 1); font-weight: 400; font-size: 14px; margin-bottom: 0px; }
.foot-righttext h2 { color: rgba(180, 205, 32, 1); font-weight: 600; font-size: 20px; margin-bottom: 15px; }
.foot-righttext ul { margin: 0; padding: 0; }
.foot-righttext ul li { list-style: none; color: rgba(255, 255, 255, 1); font-weight: 400; font-size: 15px; margin-bottom: 0px; padding-bottom: 10px;  }
.foot-righttext ul li a { color: rgba(255, 255, 255, 1); }fff
.foot-contnet img { height: 32px; }

.customer-text h6 { color: #181818; font-weight: 400; font-size: 39px; margin-bottom: 0px; padding-top: 25px; }
.customer-text h5 { color: #7A7A7A; font-weight: 500; font-size: 14px; margin-bottom: 0px; padding-top: 32px; line-height: 22px;  }
.satisfied-customers { background: #69A227; padding: 37px 30px; }
.satisfied-customers h6 { color: #FFFFFF; font-weight: 500;  font-size: 40px; margin-bottom: 0px; }
.satisfied-customers p { color: #FFFFFF; font-weight: 500; font-size: 14px; margin-bottom: 0px; padding-top: 7px; }

.satisfied-content h4 { color: #1D2F41; font-weight: 500; font-size: 14px; margin-bottom: 0px; padding-bottom: 18px; }
.satisfied-content h4:last-child { padding-bottom: 0px; }
.satisfied-content h4 i { color: #69A227; font-size: 15px; padding-right: 5px; }
.customers-section {  background-repeat: no-repeat; }
.deals-promotionssec { background: url(../images/deal-bg.png); background-repeat: no-repeat; width: 100%; height: 100%; background-size: cover; }

.deals-promotionscontent { background: #fff; }
.arrow-box { background: #69A227; border-radius: 2px; text-align: center; padding: 11px 15px; }
.border-rightnew { border-right: 0.5px solid #E7E7E7; }
.deals-promotionscontent h2 { color: #000000; font-weight: 700; font-size: 24px; margin-bottom: 0px; padding-top: 35px; }
.deals-promotionscontent h6 { color: #69A227; font-weight: 400; font-size: 20px; margin-bottom: 35px; padding-top: 15px; }
.ship-imgbox img { height: 60px; }

.range-productbox h6 { color: #69A227; font-weight: 600; font-size: 20px; margin-bottom: 0px; text-align: center; }
.range-productimgbox { width: 100%; height: 210px; }
.range-productimgbox img { width: 100%; height: 100%; object-fit: cover; }

.range-productcontentbox { min-height: 164px; background: #FFFFFF; padding: 25px 25px; box-shadow: 1px 3px 204px 0px #00000026; margin: 0px 15px; margin-top: -95px;
position: relative; z-index: 10; }
.range-productcontentbox h2 { color: #69A227; font-weight: 700; font-size: 18px; margin-bottom: 0px; }
.range-productcontentbox p { color: #000000; font-weight: 400; font-size: 15px; margin-bottom: 0px; padding-top: 13px; }
.view-detailsbtn { background: #69A227; border-radius: 2px; padding: 5px 20px; color: #FFFFFF; font-size: 14px; font-weight: 600; }
.view-detailsbtn:hover { color: #fff; }
.range-productcontentbox h3 { color: #69A227; font-weight: 600; font-size: 15px; margin-bottom: 0px; }
.range-productbox { position: relative; }
.product-box { background: #69A22733; border-radius: 30px 0px 0px 30px; width: 145px; position: absolute; right: 0px; top: 22px;
color: #69A227; padding: 3px 12px; font-weight: 400; font-size: 13px; text-align: center; }
.parts-textcontent { background: #181818; padding: 45px 30px; height: 100%; display: flex; flex-direction: column; justify-content: end; }

.parts-textcontent h3 { color: #B4CD20; font-weight: 400; font-size: 20px; margin-bottom: 0px; margin-bottom: 0px; }
.parts-textcontent h6 { color: #D1D1D1; font-weight: 500; font-size: 14px; margin-bottom: 0px; padding-top: 15px; line-height: 22px; }

.verified-seller { background: #B4CD20; height: 100%; padding: 45px 30px; display: flex; flex-direction: column; justify-content: end; }
.verified-seller h3 { color: #181818; font-weight: 400; font-size: 20px; margin-bottom: 0px; }
.verified-seller h6 { color: #303030; font-weight: 500; font-size: 15px; margin-bottom: 0px; padding-top: 15px; line-height: 22px; }

.parts-imgbox { width: 100%; height: 259px; }
.parts-imgbox img { width: 100%; height: 100%; object-fit: cover; }
.plain-section { background: url(../images/Section.png); background-repeat: no-repeat; width: 100%;}
.why-sec { padding-top: 105px; padding-left: 80px; }
.why-sec h2 { font-weight: 700; font-size: 47px; color: #FFFFFF; margin-bottom: 0px; }
.online-text { color: #FFFFFF; font-weight: 400; font-size: 18px; margin-bottom: 0px; }

.subscribe-annuallysec { background: #F5F5F5; }
.inner-standardplan { display: flex; align-items: center; justify-content: space-between; margin-bottom: 18px; }
.standard-plan { background: #FFFFFF; border: 1px solid #EDEFF3; padding: 70px 40px 40px 40px; margin: 0px 10px;
margin-top: -38px; }
.standard-plan p { color: #646464; font-weight: 400; font-size: 15px; margin-bottom: 0px; }
.standard-plan h6 { color: #646464; font-weight: 700; font-size: 15px; margin-bottom: 0px; }
.premium-plan p { color: #69A227; }
.premium-plan h6 { color: #69A227; }
.standard-btn { background: #B4CD20; color: #FFFFFF; font-weight: 600; font-size: 20px; padding: 10px 25px; border-radius: 1px;
margin-bottom: -65px; }
.premiumplan-btn { background: #69A227; color: #FFFFFF; font-weight: 600; font-size: 20px; padding: 10px 25px; border-radius: 1px;
margin-bottom: -65px; }

.premiumplan-btn:hover {  color: #FFFFFF; }

.top-bannerimg h2 { color: #FFFFFF; font-weight: 400; font-size: 35px; margin-bottom: 0px; position: absolute; z-index: 10; top: 111px;
left: 0; right: 0; text-align: center; }
.standard-btn:hover { color: #fff; }
.card-box { border: 1px solid #EDEFF3; background: #fff; min-height: 442px; }
.blog-imgbox { width: 100%; height: 250px; }
.blog-imgbox img { width: 100%; height: 100%; }
.card-box p { display: -webkit-box; -webkit-line-clamp: 2; overflow: hidden; -webkit-box-orient: vertical; color: #6E777D; font-weight: 400; font-size: 16px; margin-bottom: 0px; }
.card-box p span { display: -webkit-box; -webkit-line-clamp: 2; overflow: hidden; -webkit-box-orient: vertical; }
.card-box h3 { color: #000000; font-weight: 600; font-size: 21px; padding-top: 15px; margin-bottom: 0px; }

.testimonial-sec { background: url(../images/test-bg.png); background-repeat: no-repeat; width: 100%; background-size: cover;
background-position: center; height: 107vh; }

.testimonial-box { background: #FFFFFF; box-shadow: 1px 2px 204px 0px #0000001A; padding: 30px 20px; margin-top: 30px; }
.testimonial-box h3 { color: #69A227; }
.testimonial-box h6 { color: #000000; font-weight: 700; font-size: 20px; margin-bottom: 10px; }

.testimonial-contentbox { background: #FFFFFF; box-shadow: 1px 2px 204px 0px #0000001A; padding: 15px 15px; width: 230px; min-height: 210px;} 
.testimonial-userpic { width: 90px; height: 90px; margin: 0 auto; margin-top: -50px; }
.testimonial-userpic img { width: 100%; height: 100%; object-fit: cover; border-radius: 50%;  border: 12px solid #F3F3F3; } 
.testimonial-contentbox h2 { color: #000000; font-weight: 600; font-size: 16px; margin-bottom: 20px; padding-top: 18px; }
.testimonial-contentbox p { color: #8A8A8A; font-weight: 400; font-size: 14px; margin-bottom: 0px; padding-top: 20px; }

.review-btn { background: #FFFFFF; border-radius: 2px; color: #69A227; font-weight: 600; font-size: 15px; padding: 8px 22px; }
.review-btn:hover { color: #69A227; }
.moble-logo { height: 32px; }


// model======================

.modal { position: fixed; top: 0; left: 0; z-index: 1050; width: 100%; height: 100%; outline: 0; padding: 0 !important; }

.home-pop  .btn-close{ background: url(../images/cancel.png) no-repeat top right;  border: none; height: 25px; width: 25px; filter: invert(1); }
.home-pop  { background:  #404040; }
.home-popcontent ul { margin: 0; padding: 0; }
.home-popcontent ul li { list-style: none; color: #ffffff; margin-bottom: 20px; font-size: 15px; font-weight: 500; }
.home-popcontent ul li a {  color: #ffffff; }

 .modal-dialog { position: relative; width: auto; pointer-events: none; }

 .qr-popup .modal-dialog { background: none; }
 .qr-popup  .btn-close  { display: none; }

 .modal.fade.modal-dialog-slideout .modal-dialog {
     -webkit-transform: translate(-100%) scale(1); 
       transform: translate(-100%) scale(1);  
     margin: 0 0 0 auto;
     height: 100%;
 }
 .modal.fade.show.modal-dialog-slideout .modal-dialog {
     /* -webkit-transform: translate(0); */
      transform: translate(0); 
     display: flex;
     align-items: stretch;
     -webkit-box-align: stretch;
     min-height: 100%;
 }
 
 .modal-content {
     position: relative;
     display: flex;
     flex-direction: column;
     width: 100%;
     pointer-events: auto;
     background-color: transparent;
     background-clip: padding-box;
     border: 0 solid transparent;
     border-radius: 0;
     outline: 0;
 }
 
 .modal-body {
     position: relative;
     flex: 1 1 auto;
     padding: 1rem 40px; 
     overflow-y: auto;
     overflow-x: hidden;
 }
 .modal-open .modal {
     overflow-x: hidden;
     overflow-y: auto;
 }
 .modal-footer {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
     padding: 1rem 30px;
     border-top: 0 solid #eeeef1;
     border-bottom-right-radius: 0;
     border-bottom-left-radius: 0;
 }
 .modal-header {
     align-items: center;
     border-bottom: 0 solid #eeeef1;
     border-top-left-radius: 0;
     border-top-right-radius: 0;
 }

 
 .signup-bg { background: url(../images/signup-bg.png); background-repeat: no-repeat; width: 100%; background-size: cover; }
 .signup-lefttextbox { padding-top: 50px; }
 .signup-lefttextbox h1 { margin-top: 50px; color: #FFFFFF; font-weight: 400; font-size: 35px; margin-bottom: 0px; }
 .signup-logo { padding-top: 30px; height: 85px; }
 .signup-righttextbox h3 { color: #FFFFFF; font-size: 30px; font-weight: 400; margin-bottom: 0px; }
 .input-box { background: #F0EFFF; padding: 27px 20px; border-radius: 8px; font-weight: 400; font-size: 14px; border: none; color: #69A227; }
 .input-box:focus { background: #F0EFFF; box-shadow: none; outline: none; border: none; color: #69A227; }
 .input-box::placeholder { color: #69A227; }
 .new-checkfilledlabel { color: #FFFFFF !important; font-weight: 400 !important; font-size: 10px !important; }
 .next-btn { background: #69A227; box-shadow: 0px 4px 61px 0px #4D47C366; border-radius: 9px; padding: 15px 10px; font-weight: 400;
 font-size: 16px; border: none; color: #FFFFFF; }
 .newlogin-btn { background: #69A227; border-radius: 9px; font-weight: 400; font-size: 14px; color: #FFFFFF; border: none;
 padding: 9px 22px; }
 .signup-righttextbox h5 { font-weight: 400; font-size: 13px; margin-bottom: 0px; color: #FFFFFF; }
 .signup-righttextbox h6 { font-weight: 400; font-size: 14px; text-align: center; margin-bottom: 0px; padding-top: 10px; color: #B5B5B5; }
 .social-iconregister { text-align: center; padding-top: 20px; }
 .social-iconregister img { height: 40px; margin-right: 8px; }
 .eyes-imgpswd { position: absolute; right: 18px; top: 19px; }
 .new-pswdimg  {  top: 27px; }
 .padding-right { padding-right: 50px !important; }
 .question-bg { height: 100vh; }
 .signup-righttextbox p { color: #FFFFFF; font-weight: 400; font-size: 14px; margin-bottom: 0px; padding-top: 10px; line-height: 19px; }
 .verification-input { text-align: center; font-weight: 400; font-size: 14px; color: #69A227; border-radius: 8px; border: none; width: 48px; margin-right: 10px; padding: 15px 10px; background: #FFFFFF; }
 .verification-input:focus { border-radius: 8px; border: none; background: #FFFFFF; box-shadow: none; outline: none; }
 .verification-input:last-child { margin-right: 0px; }

 .newselect-box { background: #F0EFFF; height: 54px; border-radius: 8px; font-weight: 400; font-size: 14px; border: none; color: #69A227;}
 .newselect-box:focus { background: #F0EFFF; box-shadow: none; outline: none; border: none; color: #69A227;}


.new-numbervalue { border-top-left-radius: 8px;  border-bottom-left-radius: 8px;}
.text-valuenew { background: #F0EFFF; height: 54px !important; }




//about ============================================

.range-productcontentbox h3:hover { cursor: pointer; border-bottom: 2px solid #69a227; }
.login-btn:hover { background: #69a227;  color: white;  }
.sea-btn:hover {  background: #69a227;  color: white; }
.about-section { 
    // background: url(../images/about-newbg.png); 
    background-repeat: no-repeat !important; 
    background-size: cover !important;
background-position: center !important; min-height: 300px; display: flex; align-items: center; justify-content: center; }
.top-headabout h2 { color: #69A227; font-weight: 400; font-size: 50px; margin-bottom: 0px; }
.about-rightcontent p { color: #000000; font-weight: 400; font-size: 17px; margin-bottom: 0px; line-height: 30px; }
.our-storysection { background: linear-gradient(90deg, #fff 43%, #D9D9D93B 36%); } 
.about-leftimg { margin-bottom: -70px; }
.portal-features { background: #69A2271A; padding: 40px 37px; }
.portal-features ul { margin: 0; padding: 0; }
.portal-features ul li { padding-left: 35px; background-size: 14px; padding-bottom: 25px; list-style: none; color: #000000;
font-weight: 600; font-size: 14px; background: url(../images/green-dots.svg); background-repeat: no-repeat;  }
.pseudo-img { position: absolute; right: 0px; bottom: -30px; }
.our-missionsection {  background: linear-gradient(90deg, #69A227 65%, #fff 35%); }
.mission-contentbox h2 { color: #FFFFFF; font-weight: 700; font-size: 48px; margin-bottom: 0px; text-transform: uppercase; }
.mission-imgborder { border: 20px solid #fff; box-shadow: 1px 2px 204px 0px #0000001A; }
.mission-contentbox p { color: #FFFFFF; font-weight: 400; font-size: 22px; margin-bottom: 0px; line-height: 32px; padding-top: 30px; }
.mission-contentbox p span { font-weight: 700 !important; font-size: 20px !important; font-family: 'Goldman', cursive !important; }
.our-investors h2 { color: #69A227; }
.our-investors p { color: #000000; font-weight: 400; font-size: 22px; margin-bottom: 0px; }
.about-bannertext h4 { color: #FFFFFF; font-weight: 400; font-size: 60px; margin-bottom: 0px; }
.why-section { background: url(../images/why-sectionbg.png); background-repeat: no-repeat; background-size: cover; width: 100%;
background-position: center; min-height: 430px; }
.why-cardbox { background: #FFFFFF; box-shadow: 1px 2px 204px 0px #0000001A; padding: 40px 40px; }

.why-cardbox ul { margin: 0; padding: 0; }
.why-cardbox ul li { padding-left: 35px; background-size: 14px; padding-bottom: 30px; list-style: none; color: #000000;
font-weight: 600; font-size: 14px; background: url(../images/green-dots.svg); background-repeat: no-repeat;  }
.why-cardbox ul li:last-child { padding-bottom: 0px; }
.why-sectioncardbox { margin-top: -105px; }


// blogs ===========================================================

.blogs-section { background: url(../images/blogs-bg.png); }
.blogs-headtop h6 { color: #69A227; font-weight: 600; font-size: 22px; margin-bottom: 0px; }
.blog-contentleft h6 { color: #252525; font-weight: 700; font-size: 24px; margin-bottom: 0px; padding-top: 30px; }
.blog-contentleft p { color: #7C7C7C; font-weight: 400; font-size: 16px; margin-bottom: 0px; padding-top: 15px; }
.blog-contentleft .secound-haed { color: #252525; font-weight: 700; font-size: 24px; margin-bottom: 0px; border-bottom: 3px solid #69A227;
padding-bottom: 7px; }
.blog-rightcontent h6 { color: #69A227; font-weight: 700; font-size: 18px; padding-top: 17px; margin-bottom: 0px; }
.blog-rightcontent h5 { color: #252525; font-weight: 700; font-size: 22px; margin-bottom: 0px; padding-top: 10px; }


// wishlist
.wishlist-section { background: url(../images/wishlist.png); min-height: 250px; }
.wishlist-cardbox { background: #FFFFFF; box-shadow: 1px 4px 24px 0px #0000001A; position: relative; }
.img-boxwishlist { width: 100%; height: 180px; }
.img-boxwishlist img { width: 100%; height: 100%; object-fit: cover; }
.wishlist-cardbox p { color: #000000; font-weight: 600; font-size: 16px; margin-bottom: 0px; line-height: 20px; }
.wishlist-cardbox h6 { color: #000000; font-weight: 600; font-size: 16px; margin-bottom: 0px; }
.wishlist-cardbox h4 { color: #B7B7B7; font-weight: 400; font-size: 14px; margin-bottom: 0px; padding-top: 12px; }
.contact-btn { background: #69A22733; color: #69A227; font-weight: 400; font-size: 16px; padding: 4px 30px; border-radius: 0px; }
.contact-btn:hover { color: #69A227; }
.cross-box { background: #69A227; width: 27px; height: 27px; display: block; text-align: center; border-radius: 50%; position: absolute;
right: -10px; top: -10px; }

.wishlist-headtop h6 { color: #757575; font-weight: 400; font-size: 24px; margin-bottom: 0px; position: relative; }
.wishlist-headtop h6::after  { content: ""; position: absolute; border-bottom: 2px solid #69A227; width: 215px; bottom: -13px; left: 34px;
right: 0px;  }

//range-product

.range-section  { background: url(../images/range-bg.png); }
.rangeright-contentbox h6 { color: #69A227; font-weight: 800; font-size: 18px; margin-bottom: 0px; }
.rangeright-contentbox p { color: #374151; font-weight: 600; font-size: 16px; margin-bottom: 0px; padding-top: 10px; }
.rangeright-contentbox h5 { color: #1F2937; font-weight: 700; font-size: 32px; margin-bottom: 0px; padding-top: 20px; }
.rangeright-contentbox h4 { color: #1F2937; font-weight: 600; font-size: 14px; margin-bottom: 0px; padding-top: 22px; }
.range-productvalue { background: #69A227; border-radius: 30px; padding: 5px; }
.range-btn { border: none; }

.minus-boxrange { background: #FFFFFF; width: 20px; height: 20px; border-radius: 50%; display: block; text-align: center; color: #69A227;
line-height: 18px; position: relative; } 
.minus-boxrange i { font-size: 13px; }

.range-inptbox { background: #fff; width: 50px; height: 50px; border-radius: 50%; padding: 2.3px; position: absolute; left: 0; right: 0; margin: 0 auto; top: -12px; }
.range-inpt { padding: 8px; width: 50px; height: 50px; border: 1.5px solid #8F8F8F; border-radius: 50%; text-align: center; font-weight: 400; font-size: 18px; }
.range-inpt:focus { border: 1.5px solid #8F8F8F; box-shadow: none; outline: none; }
.range-inpt::placeholder { color: #1F2937; }

.sconnect-btn {  background: #69A227; font-weight: 700; font-size: 16px; color: #fff; padding: 9px 38px; border-radius: 0px; }
.sconnect-btn:hover { color: #FFFFFF; }
.new-seabtn { padding: 9px 38px; }
.rangeright-contentbox h2 { color: #69A227; font-weight: 600; font-size: 26px; margin-bottom: 0px; padding-top: 10px; }
.details-section p { color: #666666; font-weight: 400; font-size: 15px; margin-bottom: 0px; padding-top: 20px; }
.details-section h6 { color: #333333; font-weight: 400; font-size: 20px; margin-bottom: 0px; padding-top: 10px; text-transform: uppercase; }
.details-section h6::after { content: ''; display: block; position: relative; width: 60px; border-bottom: 2px solid #69A227;
margin-top: 8px; text-align: center; left: 15px;  }


.Category-contentbox { background: #F3F4F6; border-radius: 7px; padding: 32px 40px; }
.Category-contentbox h6 { color: #1E2910; font-weight: 600; font-size: 16px; margin-bottom: 0px; padding-top: 20px; }
.Category-contentbox p { color: #545454; font-weight: 300; font-size: 16px; margin-bottom: 0px; }
.Category-contentbox h5 { color: #6B7280; font-weight: 400; font-size: 16px; margin-bottom: 0px; line-height: 21px; }

.Category-contentbox ul { margin: 0; padding: 0; padding-inline-start: 20px; margin-top: 15px; }
.Category-contentbox ul li { color: #6B7280; font-weight: 400; font-size: 16px;  }
.range-slideimgbox { width: 100%; height: 415px; }
.range-slideimgbox img { width: 100%; height: 100%; object-fit: cover; }

.slick-slide>div { padding: 0 10px; }
.slick-list { margin: 0 -10px; }

.range-slide .slick-next { width: 28px; height: 97%; border: 1px solid #69A227; right: -47px; }
.range-slide .slick-prev { width: 28px; height: 97%; border: 1px solid #69A227; left: -46px; }
.range-slide .slick-prev:before { content: ""; background: url(../images/left-green.svg); background-repeat: no-repeat; opacity: 1;
position: absolute; z-index: 10 !important;  bottom: inherit; left: -10px;  content: ""; width: 20px; height: 20px; top: 36px; left: 1px; }
.range-slide .slick-next:before { content: ""; background: url(../images/right-green.svg) no-repeat !important; position: absolute;
z-index: 10; bottom: auto !important; right: 0px; content: ""; width: 20px; height: 20px; right: 5px; top: 37px; }
.range-productimgleft { width: 100%; height: 108px; }
.range-productimgleft img { width: 100%; height: 100%; object-fit: cover; }

.saying-text { color: #757575; font-weight: 700; font-size: 20px; margin-bottom: 0px; }

.review-textrightcontent {  border: 1px solid #ECECEC; padding: 20px 30px; border-radius: 5px; margin-top: 30px; } 
.review-textrightcontent h1 { color: #000000; font-weight: 400; font-size: 18px; margin-bottom: 0px; }
.review-textrightcontent p { color: #ABABAB; font-weight: 400; font-size: 14px; margin-bottom: 0px; padding-top: 5px; }
.review-textrightcontent h2 { color: #5E5E5E; font-weight: 600; font-size: 16px; margin-bottom: 0px; padding-top: 15px; }
.review-textrightcontent h4 { color: #000000; font-weight: 600; font-size: 16px; margin-bottom: 0px; }
.user-reviewpicbox { width: 50px; height: 50px; }
.user-reviewpicbox img { width: 100%; height: 100%; object-fit: cover; border-radius: 10px; }
.rating-stars { text-align: right; }
.rating-stars img { margin-right: 5px; }
.left-borderfeedback {  border-left: 1px solid #000000; }

.experiance-slide .slick-next { display: block; width: 22px; height: 22px; background: #FFFFFF; box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.35);
border-radius: 50%; }
.experiance-slide .slick-prev { width: 22px; height: 22px; background: #FFFFFF; box-shadow: 0px 4px 14px 0px #00000040;
z-index: 10 !important; left: -11px !important; right: 115px !important; border-radius: 50%; }
.experiance-slide .slick-next { right: -8px; }
.experiance-slide .slick-prev:before { background: url(../images/left-green.svg); background-repeat: no-repeat;  position: absolute;
z-index: 10 !important;  bottom: inherit; left: -10px;  content: ""; width: 20px; height: 20px; top: -1px; left: -1px; opacity: 1; }

.experiance-slide .slick-next:before { background: url(../images/right-green.svg) no-repeat !important; position: absolute;
z-index: 10; bottom: auto !important;  right: 0px; content: ""; width: 20px; height: 20px; top: -1px; left: 1px; }

.experiance { position: relative; }
.experiance-layer { position: absolute; width: 100%; height: 100%; }
.experiance-img { width: 100%; height: 96px; }
.experiance-img img { object-fit: cover; height: 100%; width: 100%; }

.form-boxcontent h1 { color: #000000; font-size: 18px; margin-bottom: 0px; font-weight: 600; }
.form-boxcontent h1 span { color: #ABABAB; font-weight: 300; font-size: 14px; }

.form-boxcontent h5 img { margin-right: 5px; height: 19px; }
.form-boxcontent h5 { margin-top: 2px; }
.new-labelexp { color: #5E5E5E; font-weight: 300; font-size: 16px;  }
.new-labelexp span { font-weight: 300; font-size: 14px;  color: #ABABAB; padding-left: 7px; }
.textarea-box { background: #F4F4F4; border: 1px solid #D6D6D6; border-radius: 0px; font-size: 12px; }
.textarea-box:focus { background: #F4F4F4; border: 1px solid #D6D6D6; outline: none; box-shadow: none; }
.review-checklabel { color: #000000 !important; font-weight: 300 !important; font-size: 12px !important;  }
.newupload-btn-wrapper { position: relative; overflow: hidden; display: block; text-align: left; }
.newupload-btn-wrapper .uploadBtn { outline: none; text-align: left;  border: none; font-weight: 500; font-size: 13px; color: #FFFFFF;
background: #F4F4F4; border: 1px dashed #D6D6D6; border-radius: 5px; padding: 28px 25px; width: 100%; text-align: center; }
.upload-btn-wrapper input[type=file] { font-size: 100px; position: absolute; left: -124px; top: 0; opacity: 0; cursor: pointer; height: 500px; }

.back-boxarrow { background: #69A227; padding: 15.2px 16px; }
.choose-plantable.table-bordered { border: 1px solid #BEBEBE; }
.choose-plantable.table-bordered td, .table-bordered th { border: 1px solid #BEBEBE; }
.choose-plantable thead th { border-bottom: 1px solid #BEBEBE; border-bottom-width: 1px; padding: 5px 0.75rem; }
.choose-plantable td { font-weight: 400; font-size: 14px; text-align: center; padding: 8px 0.75rem; border-top: none !important; border-bottom: none !important; }
.choose-plantable td i { font-size: 7px; margin-right: 5px; }
.choose-plantable td.subscribe-button { background: transparent !important; border-top: 1px solid #BEBEBE !important; }
.choose-plantable td.blank-td { border-bottom: none !important; }
.choose-plantable td:first-child { text-align: left; color: #000000; font-weight: 400; font-size: 14px; }
.choose-plantable td:nth-child(2) { color: #353535;  background: #69A2271A; }
.choose-plantable td:nth-child(3) { color: #353535; background: #69A2274D; }
.choose-plantable td:nth-child(4) { color: #FFFFFF; background: #69A22780; }
.choose-plantable td:nth-child(5) { color: #FFFFFF; background: #69A227B2; }

.choose-plantable th { text-align: center; }
.choose-plantable th:first-child { text-align: left;  }
.choose-plantable thead th:first-child { color: #8F8F8F; font-weight: 400; font-size: 28px; }
.choose-plantable thead th:nth-child(2) { color: #8F8F8F; font-weight: 400; font-size: 30px; }
.choose-plantable thead th:nth-child(3) { color: #69A227; font-weight: 400; font-size: 30px; }

.subscribe-btn { background: #F0F6E9; font-weight: 600; font-size: 18px; padding: 5px 30px; color: #69A227; border-radius: 0px; }
.subscribe-btn2 { background: #D2E3BE; }
.subscribe-btn3 { background: #B4D093; color: #fff; }
.subscribe-btn4 { background: #96BE68; color: #fff; }

.policy-text h2 { color: #000000; font-weight: 400; font-size: 18px; margin-bottom: 0px; }
.policy-text h2 span { color: #000000; font-weight: 700; font-size: 18px; }
.offer-topsection { background: #69A22726; border-radius: 25px; }

.offer-content h2 { color: #191919; font-weight: 700; font-size: 24px; margin-bottom: 0px; }
.offer-content p { color: #191919; font-weight: 400; font-size: 16px; margin-bottom: 0px; padding-top: 30px; }
.offer-leftimg { border-bottom-left-radius: 25px; border-top-left-radius: 25px;  }

.photos-content h2 { color: #69A227; font-weight: 400; font-size: 34px; margin-bottom: 0px; }
.photos-content p { color: #525252; font-weight: 400; font-size: 18px; margin-bottom: 0px; padding-top: 28px; }
.advertisements-text h4 { color: #4B5563; font-weight: 700; font-size: 35px; margin-bottom: 0px; }
.advertisements-text p { color: #565969; font-weight: 400; font-size: 18px; margin-bottom: 0px; padding-top: 30px; }
.voice-content  { background: #EEEEEE; padding: 30px 25px; min-height: 346px; } 
.voice-content h5 { color: #69A227; font-weight: 600; font-size: 18px; margin-bottom: 0px; padding-top: 30px; text-transform: uppercase; }
.voice-content p { color: #000000; font-weight: 400; font-size: 16px; margin-bottom: 0px; padding-top: 20px; }

.product-imgbg::after { content: " "; background: #69A227; width: 90%; height: 98%; position: absolute; left: -5px; z-index: 0; top: -20px; }
.product-img { z-index: 10; position: relative; }
.voice-imgbox { width: 90px; height: 90px; border-radius: 20px; background: #fff; line-height: 90px; text-align: center; }

.offer-boximg1 { width: 100%; height: 375px; }
.offer-boximg1 img { width: 100%; height: 100%; object-fit: cover; }
.terms-headabout h2 { color: #000000; font-weight: 700; font-size: 45px; margin-bottom: 0px; }

.terma-conditions p { color: #818181; font-weight: 500; font-size: 16px; margin-bottom: 0px; padding-top: 20px; }
.contact-bg { background: url(../images/contact-bg.png); background-position: center; background-size: cover; background-repeat: no-repeat; padding: 70px 60px; }
.contact-frombg { background: #FFFFFF4D; padding: 60px 30px; }
.contact-input { padding: 10px 22px; font-weight: 700; font-size: 18px; background: transparent; border-bottom: 1px solid #FFFFFF !important; border: none; border-radius: 0px; }
.contact-input:focus { color: #FFFFFF; outline: none; box-shadow: none; background: transparent; border-bottom: 1px solid #FFFFFF !important; border: none;  }
.contact-input::placeholder { color: #FFFFFF; }

.new-submitbtn { font-weight: 700; font-size: 18px; border-radius: 0px; padding: 10px 22px; }
.contact-frombg p { color: #FFFFFFF2; font-weight: 600; font-size: 14px; margin-bottom: 0px; }

.contact-section { background: url(../images/contact-us.png); min-height: 200px; }


// about-Modal
.about-pop { position: fixed; top: 0; left: 0; z-index: 1050; width: 100%; height: 100%; outline: 0; padding: 0 !important; }
.about-pop .btn-close{ background: url(../images/about-popclose.svg) no-repeat;background-position: center; position: absolute;
top: 0; left: 0; border: none; height: 65px; width: 65px; background-color: #69A227 !important; }

.about-pop .modal-dialog { background: #fff; position: relative; width: auto; pointer-events: none; }
.about-pop.fade.modal-dialog-slideout .modal-dialog { -webkit-transform: translate(100%) scale(1); margin: 0 0 0 auto; height: 100%; }
.about-pop.fade.show.modal-dialog-slideout .modal-dialog { transform: translate(0); display: flex; align-items: stretch; 
-webkit-box-align: stretch; min-height: 100%; }
.about-pop .modal-content { position: relative; display: flex; flex-direction: column; width: 100%; pointer-events: auto;
background-color: transparent; background-clip: padding-box; border: 0 solid transparent; border-radius: 0; outline: 0; }

.about-pop .modal-body { position: relative; padding: 1rem 45px; overflow-y: auto; overflow-x: hidden; }
.about-pop .modal-open .modal { overflow-x: hidden; overflow-y: auto; }
.about-pop .modal-header { display: block; border-bottom: 0 solid #eeeef1; border-top-left-radius: 0; border-top-right-radius: 0; }
.about-popcontent p { color: #000000; font-weight: 600; font-size: 20px; margin-bottom: 0px; }
.aboutpop-head h6 { color: #69A227; font-weight: 400; font-size: 40px; margin-bottom: 0px; }

// end modal

.search-icon { position: absolute; top: 13px; left: 15px; }
.product-input { border: 1px solid #ACACAC; padding: 21px 20px; padding-left: 42px !important; border-radius: 0px; font-size: 14px; font-style: italic; font-weight: 400; }
.product-input:focus {  border: 1px solid #ACACAC; box-shadow: none; outline: none; }
.product-input::placeholder { color: #ACACAC; }
.category-box { background: #69A2271A; padding: 12px 30px; }
.category-label { color: #3D3D3D !important; font-size: 14px !important; font-weight: 600 !important; }
.category-mainlabel { color: #374151 !important; font-size: 16px !important; font-weight: 600 !important; }

.product-detailsbox { background: #fff; padding: 18px 18px; box-shadow: 1px 2px 100px rgba(0, 0, 0, 0.07); }
.product-detailsbox h4 { color: #3D3D3D; font-size: 16px; font-weight: 600; margin-bottom: 0px; padding-top: 10px; }
.product-detailsbox h6 { color: #9A9A9A; font-size: 14px; font-weight: 400; padding-top: 5px; margin-bottom: 0px; }
.product-detailsbox h3 { font-size: 16px; font-weight: 600; margin-bottom: 0px; text-align: center; }
.promoted-product { margin-top: 5px; position: relative; padding-left: 38px !important; background: #69A227; border-radius: 30px; padding: 5px 15px; font-size: 14px; font-weight: 400; color: #fff; }
.promoted-product-range{ margin-top: 5px; position: absolute; padding-left: 38px !important; background: #69A227; border-radius: 30px; padding: 5px 15px; font-size: 14px; font-weight: 400; color: #fff; margin-left: 181px; top: -5px; right: 0px;}
.reward-img { position: absolute; top: -11px; left: 11px; }

.product-pricelabel p { color: #69A227; font-size: 16px; font-weight: 600; margin-bottom: 0px; }
.product-pricelabel {  border-bottom: 1px solid #E2E2E2 }

//commit-css========================
.filter-drop .dropdown-menu { width: 230px; padding: 18px 15px; box-shadow: 2px 3px 16px 3px rgba(0, 0, 0, 0.20); border-radius: 5px; border: none; }
.filter-drop .dropdown-toggle::after { display: none; }
.filter-drop button { background: #69A227; border-radius: 0px; border: 1px solid #69A227; padding: 8px 7px; }
.filter-drop button:hover { background: #69A227; border: 1px solid #69A227; }
.filter-drop .btn-primary.dropdown-toggle { color: #fff; background-color: #69A227; border-color: #69A227; }
.filter-drop .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff; background-color: #69A227; border-color: #69A227; }
.filter-drop p { color: #8D8D8D; font-size: 14px; font-weight: 300; margin-bottom: 0px; }
.filter-drop h6 { color: #69A227; font-size: 12px; font-weight: 400; margin-bottom: 0px; }

//buy-form==================
.buy-section { background: linear-gradient(180deg, rgba(105, 162, 39, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%); }
.buy-formtext h1 {  font-weight: 400; font-size: 27px; color: #69A227; margin-bottom: 0px;  }
.heading-seperator { position: relative; margin: 15px 0 30px}
.heading-seperator span:after { content: ""; left: 152px; width: 36px; border-bottom-width: 5px; border-radius: 30px; position: absolute; bottom: 0; margin-left: 0;
 border-bottom-color: #69A227; border-bottom-style: solid;}
.heading-seperator:after { content: ""; left: 0%; position: absolute; bottom: 0px; width: 147px; border-bottom-width: 5px; border-radius: 30px;  margin-left: 0px;
border-bottom-style: solid; border-bottom-color: #69A227;}
.buy-tabs .nav-link { padding: 0.6rem 1.5rem; }
.buys-label { font-weight: 700; font-size: 14px; color: #5E5873; }
.maximum-uploadedtext { font-weight: 400; font-size: 11px; color: #737373; }
.buy-select { border: 1px solid #D8D6DE; background: #FFFFFF; border-radius: 5px; font-weight: 400; font-size: 12px; color: #B9B9C3; height: 38px; }
.buy-select:focus { box-shadow: none;  border-radius: 5px;  outline: none; background: #FFFFFF; border: 1px solid #D8D6DE; color: #B9B9C3;  }

.input-group-text { background: #69A227; }
.buy-select::placeholder { color: #B9B9C3; }
.upload-btn-wrapper { position: relative; overflow: hidden; display: block; text-align: right; }
.uploadBtn { background: #69A227; outline: none; text-align: center;  border: none; font-weight: 500; font-size: 13px; color: #FFFFFF; }
.upload-btn-wrapper input[type=file] {font-size: 100px; position: absolute; left: -124px; top: 0; opacity: 0; cursor: pointer; height: 500px;
font-size: 20px; width: 900px; }
.upload-btnpadding { padding: 6px 20px;  }

.buy-tabs { border-bottom: none; } 
.buy-tabs .nav-link { margin-bottom: -1px; border: none; border-top-left-radius: 0px; 
border-top-right-radius: 0px;  background: #69A22714 !important; }
.buy-boxcontent p { margin-bottom: 0px; border: 1px solid #000000; padding: 4px 12px; background: #fff; border-radius: 5px;
font-weight: 700; font-size: 15px; color: #353535;  }
.buy-boxcontent h6 { margin-bottom: 0px; margin: 0px 20px; font-weight: 700; font-size: 15px; color: #353535; }
.buy-boxcontentnew h6 { margin-right: 0px; }
.buy-tabs .nav-link.active p { color: #69A227; border: 1px solid #69A227; }
.buy-tabs .nav-link.active h6 { color: #69A227; }
.back-btn { color: #69A227; font-size: 18px; font-weight: 600; border: 1px solid #69A227; border-radius: 10px; padding: 8px 30px; }
.back-btn:hover { color: #69A227; }
.pre-btn { color: #fff; font-size: 18px; font-weight: 600; border: 1px solid #69A227; border-radius: 10px; background: #69A227; padding: 8px 30px; }
.pre-btn:hover { color: #fff; }
.Advertisement-box h1 { font-weight: 700; font-size: 16px; color: #69A227; margin-bottom: 0px; }
.Advertisement-box p { font-weight: 400; font-size: 16px; color: #666666; margin-bottom: 0px; padding-top: 5px; }
.secound-buybox { width: 100%; height: 110px; position: relative; }
.secound-buybox .first-buyimg { width: 100%; height: 100%;  object-fit: cover; }
.secound-buybox .cross-img { position: absolute; right: -12px; top: -11px; }

.payment-contentpop h1 { color: #69A227; font-weight: 600; font-size: 22px; margin-bottom: 0px; }
.scroll-pop .btn-close { border: none; }
.scroll-pop .modal-dialog  {  width: 400px; border: none; border-radius: 5px; border: 1px black solid;  }



//rules==========================

.rules-contentbox h6 { color: #575757; font-size: 22px; font-weight: 600; margin-bottom: 0px; }
.rules-box { background: rgba(105, 162, 39, 0.10); border-radius: 10px; border: 1px rgba(105, 162, 39, 0.60) solid; padding: 22px 50px; }
.rules-box h6 { color: #505050; font-size: 18px; font-weight: 700; margin-bottom: 0px; }
.rules-box h4 span { color: #69A227; font-size: 19px; font-weight: 700; padding-right: 15px; }
.rules-box h4 { color: #505050;  font-size: 16px; font-weight: 400; margin-bottom: 0px; }

.rules-contentbox h2 { font-size: 18px; font-weight: 400; color: #69A227; margin-bottom: 0px; display: flex; align-items: center; } 
.medal-std { height: 24px; margin-right: 5px; }
.price-label { color: #505050 !important; font-size: 15px !important; font-weight: 700 !important; }
.search-boxpop1 .modal-dialog { max-width: 100% !important; border-radius: 0px; margin: 66px auto !important; background: #fff; }
.search-boxpop1 .modal-content { border: none; border-radius: 0px; padding: 100px 0px; }
.search-boxpop1 .btn-close { background: url(../images/search-cross.svg) no-repeat top right; border: none; height: 40px; width: 40px; }

.search-popinp { color: #69A227; font-size: 28px; font-weight: 400; border: none; padding: 0px; padding-right: 50px !important; }
.search-popinp:focus { border: none; outline: none; box-shadow: none; }
.search-popinp::placeholder { color: #69A227; }
.search-boxpop1 .modal-header { border-bottom: 1px solid #69A227; padding: 0px 0px;  }
.search-headerimg { cursor: pointer; }

.blogs-tabs { border-bottom: 1px solid #DBDADE; justify-content: center; }
.blogs-tabs .nav-item .nav-link { color: #4B465C; font-size: 18px; font-weight: 400;  }
.blogs-tabs .nav-item .nav-link.active { color: #69A227; font-size: 22px; font-weight: 700; border-bottom: 2px solid #69A227; }

//========================

.sealeftimg { width: 100%; height: 517px; }
.sealeftimg img { width: 100%; height: 100%; object-fit: cover; }

.advancedinnerimg { width: 100%; height: 613px; } 
.advancedinnerimg  img { width: 100%; height: 100%; object-fit: cover; } 

.aboutimgboxmaine { width: 100%; height: 345px; }
.aboutimgboxmaine img { width: 100%; height: 100%; object-fit: cover; }




.deals-promotionshoverbox { position: relative; cursor: pointer; }
.overlay-left-slide { 
    position: absolute;     
    opacity:0;
    bottom: 0;
    right: 100%;
    left: 0;
    width: 0;
    height: 100%;
    overflow: hidden;
    transition: .5s ease;
    background-color: #69A227;
    padding: 40px 40px;
  }

  .overlay-left-slide p { color: #fff; font-size: 20px; font-weight: 700; margin-bottom: 30px; text-align: left !important; }
  .deals-promotionshoverbox:hover .overlay-left-slide { right: 0; width: 100%; opacity:1; }

  .right-heroimg {
    margin-top: 0px;
}
.advertise-img {
    width: 100%;
    height: 300px;
}

.advertise-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-investors { width: 100%; height: 300px; }
.about-investors img { width: 100%; height: 100%; object-fit: cover; }

.tooltip-inner {
    background-color: #ffff;
    box-shadow: 0px 0px 4px black;
    opacity: 1 !important;
    border-radius: 10px;
    font-size: 12px;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
    border-right-color: #ffff !important;
   
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
    border-left-color: #ffff !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #ffff !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #ffff !important;
}
// .search-boxpop1{}
.textmonial-slider .slick-slide img { display: initial;}
// .why-we-text p { max-width: 100px !important;}
// .xyz { max-width: 100px !important; display: inline;}
.review-textrightcontent { height: 970px; overflow-y: scroll;}
.experiance-box { height: 400px; width: 100%;}
.experiance-box  img { height: 100%; width: 100%; object-fit: cover;}
// .video-filed-content iframe { height: 190px; width: 100%; border-radius: 5px; border: 0px;}
.video-filed-content-2 iframe{ height: 300px !important;  width: 100%; border-radius: 5px; border: 0px;}
.location-group { position: relative;}
.location-group img {position: absolute; top: 7px; right: 10px; filter: invert(0);}

.phone-input input {position: absolute !important;  top: 0px !important; bottom: 0px !important; right: 0px !important; background-color: transparent !important; border: 0px; padding: 25px; border: 0px; width: 100% !important;}
.success-pop-new .modal-content { background-color: #ffffff !important; border-radius: 5px; padding: 0px 0px;}
.success-pop-new .modal-header { padding: 0px;}
.success-pop-new .payment-contentpop p { font-size: 25px; font-weight: 600; color: #69A227; margin: 0px;}

.new-model .modal-content { background: #ffffff; }
.new-model .modal-content { background: #ffffff;}
// .new-model .btn-close {background: url(../../../public/images/cross-Icon.svg) no-repeat top right; border: none;height: 25px; width: 25px; position: absolute; right: -10px; top: -10px; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); border-radius: 3px; background-position: center; background-color: #ffffff;}
.pop-content h1 { margin-bottom: 0px; font-weight: 700; font-size: 32px; color: #69A227; padding-top: 150px; }
.pop-content h2 { font-weight: 400; font-size: 24px; color: #959595; margin-bottom: 0px; line-height: 28px; padding-top: 13px; }
.sparessfill-btn { background: #69A227; border-radius: 5px; font-weight: 600; font-size: 14px; color: #FFFFFF; padding: 6px 22px;}

.video-calluser { width: 100%; height: 100%; border-radius: 20px;}
.user-imgvideocallmainbox { border-radius: 20px; height: 200px; width: 200px; position: absolute; bottom: 50px; right: 30px;}
// .scroll-pop .modal-dialo { background: #fff; }
.scroll-pop .modal-dialog {  background: #fff; }

.back-btn {color: #69A227;font-size: 18px;font-weight: 600;border: 1px solid #69A227;border-radius: 10px;padding: 8px 30px;}
.pre-btn {color: #fff;font-size: 18px;font-weight: 600;border: 1px solid #69A227;border-radius: 10px;}

.top-headabout h6 { color: #707070; font-weight: 400; font-size: 18px; margin-bottom: 0px; text-align: center; line-height: 30px; }

.explore-box { position: absolute; top: 4px; left: 64.5%; }
.explore-box .before-explore { visibility: visible; }
.explore-box .after-explore { visibility: hidden; }
.explore-box:hover .after-explore { visibility: visible; transition: opacity 1s; margin-left: -50px; }
.explore-box:hover .before-explore { visibility: hidden; }


//===================
.mic-innermainbox { border: 1px solid #69a22733; width: 193px; height: 193px; padding: 13px; border-radius: 50%; margin: 0 auto; }
.mic-innermainbox2 {  border: 1px solid #69a22780; width: 165px; height: 165px; padding: 12px; border-radius: 50%; }
.mic-innermainbox3 { width: 140px; height: 140px; border: 1.5px solid #69A227; padding: 4px; border-radius: 50%; }
.mic-imggreen { width: 130px; height: 130px; line-height: 130px; background: #fff; text-align: center; border-radius: 50%; }
.mic-imggreen img { height: 55px; }
.voice-pagemain { min-height: 450px; border-radius: 15px; margin-top: 20px; background: rgba(105, 162, 39, 0.15); padding: 10px 10px; height: 100%; display: flex;
flex-direction: column; justify-content: center; }
.voice-userimg { width: 105px; height: 105px; border-radius: 50%; margin: 0 auto; text-align: center; }
.voice-userimg img { width: 100%; height: 100%; border-radius: 50%; object-fit: cover; }
.mic-imggreen { margin: 0 auto; text-align: center; }
.voice-pagemain p { font-weight: 600; font-size: 20px; margin-bottom: 0px; text-align: center; padding-top: 12px; }
.phone-box { margin: 0 auto; background: #FF5501; width: 50px; line-height: 48px; height: 50px; display: block; border-radius: 50%; text-align: center; }

.wishlist-heartimg img { height: 50px; }

 .personal-upload  {
    position: absolute !important;
    right: 10px;
    bottom: 25px;
}

.newlocation-group img { position: absolute; top: 16px; right: 15px; }

.main-dealbox { display: flex; }
.main-dealbox .deals-promotionshoverbox { width: 348px; text-align: center; padding: 50px 10px; border-right: 0.5px solid #E7E7E7; }
.main-dealbox .deals-promotionshoverbox:last-child { border-right: none; }

// .signup-lefttextbox h1 {  }

.new-model .modal-content { background: #ffffff; }
.new-pophead {  font-weight: 600; font-size: 20px; color: #69A227; text-align: center; margin-bottom: 0px; }


.new-model .btn-close {
    background: url(../images/green-crossnew.png) no-repeat top right;
    border: none;
    height: 25px;
    width: 25px;
    position: absolute;
    right: -10px;
    top: -10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    background-position: center;
    background-color: #ffffff;
}
.pop-table { margin-top: 15px; }
.new-model .modal-body { padding: 0px; }
.new-model .modal-header { display: block; padding: 25px 40px 0px 40px; }

.advertisement-details h4 { font-weight: 700; font-size: 18px; margin-bottom: 0px; color: #353535; }
.advertisement-details p { padding-top: 5px; font-weight: 400; font-size: 16px; margin-bottom: 0px; color: #9D9D9D; }


.banner-imgboxnew { width: 130px; height: 130px; }
.banner-imgboxnew  img { width: 100%; height: 100%; object-fit: cover; }

.pagination-container {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 20px;
}
.pagination-cstm .page-item .page-link { font-size: 12px; color: #000000; border: 1px solid #000000; margin: 0px 5px;}
.pagination-cstm .page-item .page-link:active { color: #69A227; border: 1px solid #69A227;}
.free-t-btn { color: #ffffff; background-color: #69A227;}
.free-t-btn:hover { color: #69A227; background-color: #F0F6E9;}

// ======================================================
.push-notification-pop .modal-content { background-color: #ffffff; border-radius: 10px; box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.15); height: 480px; width: 440px;}
.push-notification-pop { top: 85px; left: 430px;}
.push-notification-pop .modal-header {padding: 25px;}
.push-headtext { display: flex; justify-content: space-between; align-items: center; width: 100%;}
.push-headtext h1 { color: #69A227; font-size: 20px; font-weight: 600; margin: 0px;}
.push-headtext h5 { color: #4B465C; font-size: 15px; font-weight: 500; margin: 0px; display: flex;}
.push-notification-pop .modal-body {padding: 0px 0px; padding-top: 0px;}
.push-notification-pop .tab-content { padding-left:25px; padding-right: 15px; height: 365px; overflow-y: scroll;}
.push-context h3 { color: #4B465C; font-size: 12px; font-weight: 500; margin-bottom: 8px;}
.push-context p { color: #69A227; font-size: 12px; font-weight: 600; margin: 0px;}
.push-context ul li { color: #69A227; font-size: 12px; font-weight: 600;}   
.push-tabs-pop .nav-tabs { padding-left: 15px; border-bottom: 1px solid #E9E9E9;}
.push-tabs-pop .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active { border: none; border-bottom: 2px solid #69A227 !important;}
.push-tabs-pop .nav-tabs .nav-item .nav-link {padding-bottom: 5px; color: #5D586C; font-size: 15px; font-weight: 600; align-items: center;}
.push-tabs-pop .nav-tabs .nav-link:hover{ border-color: transparent;}
.push-tabs-pop .nav-tabs .nav-item .nav-link .badge { background-color: #69A227; color: #ffffff; border-radius: 2px; font-size: 12px; font-weight: 600; margin-left: 5px; padding: 3px 6px;}
.push-tabs-pop .nav-tabs .nav-item { margin-right: 20px;}
.push-btm-border {border-top: 1px solid #E9E9E9; margin: 15px 0px;}
.pushed-btn {font-size: 12px; font-weight: 600; padding: 5px 18px; margin-right: 15px; margin-top: 15px; border-radius: 5px; border: 0px;}
.pushed-btn-grn { background-color: #69A227; color: #FFFFFF;}
.pushed-btn-lgt { background: rgba(105, 162, 39, 0.20); color: #69A227;}
.push-notification-pop .tab-content::-webkit-scrollbar-thumb {border-radius: 40px; background: rgba(105, 162, 39, 0.50);}
.push-notification-pop .tab-content::-webkit-scrollbar { border-radius: 50px; background-color: transparent; width: 5px;}
.pushed-btn {font-size: 12px; font-weight: 600; padding: 5px 18px; margin-right: 15px; margin-top: 15px; border-radius: 5px; border: 0px;}
.pushed-btn-grn { background-color: #69A227; color: #FFFFFF;}
.pushed-btn-lgt { background: rgba(105, 162, 39, 0.20); color: #69A227;}
.push-area .push-area-list li { padding: 18px 15px; border-bottom: 1px solid #E9E9E9;}
.push-area .push-area-list .unread-list { box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -10px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -25px inset; background-color: #69a22727;}


.box-newplan .green { background: #69A227; padding: 1px 15px; width: 78px; height: 23px; }
.box-newplan .black { text-align: center; background: #000000; padding: 1px 15px; width: 78px; color: #fff; font-size: 13px; white-space: nowrap;
overflow: hidden; text-overflow: ellipsis; }

.box-newplan { position: absolute; right: 0px; top: -26px; line-height: 21px; }


// .voice-pagemain {
//     border-radius: 5px;
//     background: rgba(105, 162, 39, 0.15);
//     padding: 10px 10px;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
// }

.css-wjh20t-MuiPagination-ul { justify-content: end; }

.css-146y0mf-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    color: #69A227 !important;
    border: 1px solid #69A227 !important;
    background-color: rgba(105, 162, 39, 0.3) !important;
  }

  .slider-popupimg { width: 100%; height: 560px; }
  .slider-popupimg img { width: 100%; height: 100%; object-fit: fill; }

  .error-message {
    color: red;
    font-size: 0.875rem;
  }



